import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { useParams, useNavigate, Link, useLocation, useSearchParams } from 'react-router-dom';
import { Image, ChevronDown, HelpCircle, MessageSquare, Check,RefreshCw, Paperclip, X, ChevronRight, Search, LogOut } from 'lucide-react'; // Added X icon, Globe icon, and Calendar icon, and History icon, and Search icon, and LogOut icon
import Sidebar from './Partials/Sidebar';
import '../Styles/Chat.css';
import '../Styles/katex.css';
import '../Styles/TextRenderer.css';
import ResourcesSection from './Partials/Resources';
import { fetchMessages, createSpaceChat, updateProfile, editMessageActual, addChatToSpace, reportMessage, sendMessage, createNewChat, regenerateMessage, sendGuestMessage, addArtifactToSpace } from '../services/api';
import { shuffle } from 'lodash';
import { categorizedSuggestions } from './Data/AllSuggestions';
import {AIResponseRenderer } from './Partials/LLMTextRenderer';
import { useQuery, useQueryClient } from 'react-query';
import useDocumentTitle from '../UseDocumentTitle';
import { useAuth } from '../hooks/useAuth';
import { Helmet } from 'react-helmet';
import studyBuddyLogo from '../assets/Empty High-Res Logo.png';
import { FileText } from 'react-feather';
import { fetchChats } from '../services/api';
import { FaChevronRight } from 'react-icons/fa'
import { File } from 'lucide-react';
import ExpandedResource from './Partials/ExpandedResource';
import SalesModal from './Partials/SalesModal';
import CognoraLogo from '../assets/Empty High-Res Logo.png';
import CognoraLogoTransparentDark from '../assets/Cognore Inverse Logo (1).png'
import { useDropzone } from 'react-dropzone';
import { FaFilePdf, FaTimes } from 'react-icons/fa';
import { AlertCircle, ExternalLink,} from 'lucide-react'; // Add this import
import { BarChartOutlined } from '@ant-design/icons';
import {copyRichText } from './helpers/chatHelper';
import debounce from 'lodash/debounce';
import LoadingOverlay from './Partials/LoadingOverlay';
import { useTheme } from '../App';
import { SidebarLeftIcon, LicenseDraftIcon, ThumbsDownIcon, DashboardCircleIcon, PlusSignCircleIcon, UploadCircle01Icon, Attachment02Icon, Copy01Icon, SlidersVerticalIcon, AccountSetting03Icon, FileAttachmentIcon, ArrowLeft01Icon, PencilEdit02Icon} from 'hugeicons-react';
import RecentChats from './Layout/RecentChats';

// Add this helper function at the top of your component
const generateTempId = () => `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

// Add this helper function at the top of the file
const getGuestChatsFromStorage = () => {
  const stored = localStorage.getItem('guestRemainingChats');
  const lastReset = localStorage.getItem('guestChatsLastReset');
  const today = new Date().toDateString();

  // Check if it's a new day or no last reset exists
  if (!lastReset || lastReset !== today) {
    // Reset to default value (3) and update last reset date
    localStorage.setItem('guestRemainingChats', '3');
    localStorage.setItem('guestChatsLastReset', today);
    return 3;
  }

  // Return stored value if it exists, otherwise default to 3
  return stored ? parseInt(stored, 10) : 3;
};

// Add these constants at the top of the file
const MAX_FILES_PER_MESSAGE = 3;
const MAX_FILES_PER_CHAT = {
  free: 1,
  premium: 2,
  pro: 3,
  ultimate: 5,
  beta: 7
};
const MAX_FILE_SIZE_MB = 10;
const ACCEPTED_FILE_TYPES = {
  'application/pdf': 'pdf',
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/gif': 'image',

  'text/plain': 'text'
};

// Add this constant near the top with other constants
const MAX_MESSAGE_LENGTH = 16000;

// const LoadingMessages = () => {
//   return (
//     <div className="loading-messages">
//       {[1, 2, 3].map((i) => (
//         <React.Fragment key={i}>
//           <div className="loading-message user" />
//           <div className="loading-message ai" />
//         </React.Fragment>
//       ))}
//     </div>
//   );
// };

// Move UserDropdown outside of Chats component
const UserDropdown = React.memo(({ user, handleLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Close dropdown when pressing Escape
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  const handleDropdownClick = useCallback(() => {
    navigate('/settings');
    setIsDropdownOpen(!isDropdownOpen);
  }, [navigate, isDropdownOpen]);

  const handleLogoutClick = useCallback(() => {
    handleLogout();
    setIsDropdownOpen(false);
  }, [handleLogout]);

  return (
    <div className="dropdown">
      <button 
        ref={buttonRef}
        className="settings-button"
        onClick={handleDropdownClick}
        aria-label="Open user menu"
        aria-expanded={isDropdownOpen}
        aria-haspopup="true"
      >
        <div 
          className="user-avatar" 
          role="img" 
          aria-label={`${user.name}'s avatar`}
        >
          {user.name ? user.name.charAt(0).toUpperCase() : '?'}
        </div>
      </button>
      
      {isDropdownOpen && (
        <div 
          ref={dropdownRef}
          className="dropdown-menu"
          role="menu"
          aria-orientation="vertical"
        >
          <Link 
            to="/settings" 
            className="dropdown-item"
            role="menuitem"
            onClick={() => setIsDropdownOpen(false)}
          >
            <AccountSetting03Icon size={18} style={{ marginRight: '8px' }} />
            Settings
          </Link>

          <a
            href='https://cognora.ca/faq'
            target="_blank"
            rel="noopener noreferrer"
            className="dropdown-item"
            role="menuitem"
            onClick={() => setIsDropdownOpen(false)}
          >
            <HelpCircle size={18} style={{ marginRight: '18px' }} />
            Help
          </a>
          
          <div className="dropdown-divider"></div>

          <button 
            onClick={handleLogoutClick}
            className="dropdown-item"
            role="menuitem"
          >
            <LogOut size={18} style={{ marginRight: '4px' }} />
            Logout
          </button>
        </div>
      )}
    </div>
  );
});

// Move these outside the component or use useMemo if they need to be dynamic
//const disallowedElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'iframe', 'script', 'style', 'table', 'blockquote', 'pre'];

const Chats = ({ subscription, isSpaceChat }) => {
  const { user, isLoading: authLoading, logout } = useAuth();
  const { theme } = useTheme();
  

  useDocumentTitle('StudyBuddy');
  const initialRemainingChats = user ? user?.tier === 'free' ? user?.remainingChats : null : getGuestChatsFromStorage();
  const [remainingChats, setRemainingChats] = useState(initialRemainingChats);
  const queryClient = useQueryClient();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const inputRef = useRef('');
  const textareaRef = useRef(null);
  const [isMobile] = useState(window.innerWidth <= 768);

  // First, declare resources state
  const [resources, setResources] = useState({});

  // Then use it in other state initializations
  const [isResourcesVisible, setIsResourcesVisible] = useState(() => {
    return false;
  });
  
  const [isResourcesExpanded, setIsResourcesExpanded] = useState(false);
  const [expandedResource, setExpandedResource] = useState(null);
  const [expandedVersion, setExpandedVersion] = useState(-1);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [chatIdToUse, setChatIdToUse] = useState(null);
  const [currentChatTitle, setCurrentChatTitle] = useState('');
  const [isInputProcessing, setIsInputProcessing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesContainerRef = useRef(null);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  
  // New state for editing and branching
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedMessageId, setEditedMessageId] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const abortControllerRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const lastMessageRef = useRef(null);
  const [isDefaultDisplay, setIsDefaultDisplay] = useState(false);

  const [currentMode, setCurrentMode] = useState(() => {
    return localStorage.getItem('defaultChatMode') || 'qa';
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  const [filteredMessages, setFilteredMessages] = useState([]);

  const [regeneratingMessageId, setRegeneratingMessageId] = useState(null);

  const [generatedContent, setGeneratedContent] = useState('');

  // Add these new state variables
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [showToast, setShowToast] = useState(false);

  // Change this line to allow only one PDF file
  const [pdfFiles, setPdfFiles] = useState([]);


  const fileInputRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dynamicHeader, setDynamicHeader] = useState('');

  const location = useLocation();

    // Add new state for hover
    const [isHoverOpen, setIsHoverOpen] = useState(false);
    const hoverTimeoutRef = useRef(null);
  
    // Handle hover open/close with delay
    const handleHoverOpen = useCallback(() => {
      if (!isSidebarOpen && window.innerWidth > 668) {
        setIsHoverOpen(true);
      }
    }, [isSidebarOpen]);
  
    const handleHoverClose = useCallback(() => {
      if (!isSidebarOpen && window.innerWidth > 668) {
        setIsHoverOpen(false);
      }
    }, [isSidebarOpen]); 
    
    useEffect(() => {
      const timeoutRef = hoverTimeoutRef.current;
      return () => {
        if (timeoutRef) {
          clearTimeout(timeoutRef);
        }
      };
    }, []);

  // Add this near the other state declarations
  const [remainingGuestChats, setRemainingGuestChats] = useState(getGuestChatsFromStorage);
  const [showAuthPrompt, setShowAuthPrompt] = useState(!user && remainingGuestChats <= 0);

  // Add this useEffect to handle guest chat count
  useEffect(() => {
    if (!user) {
      localStorage.setItem('guestRemainingChats', remainingGuestChats.toString());
      localStorage.setItem('guestChatsLastReset', new Date().toDateString());
    }
  }, [remainingGuestChats, user]);

    // Add this new useEffect for handling scroll on new chats
    useEffect(() => {
      if (chatId === 'new' || !chatId || chatId === 'undefined') {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = 0;
        }
      }
    }, [chatId]);

    // Update the scrollToBottom function
    const scrollToBottom = useCallback(() => {
      const timeoutId = setTimeout(() => {
        if (messagesEndRef.current && !isGeneratingResponse) {
          try {
            messagesEndRef.current?.scrollIntoView({ 
              behavior: "smooth", 
              block: "end",
              inline: "nearest"
            });
          } catch (error) {
            // Error handling removed
          }
        }
        setShowScrollButton(false);
      }, 100);
  
      return () => clearTimeout(timeoutId);
    }, [isGeneratingResponse]);
  
    // Update the existing useEffect for initial scroll behavior
    useEffect(() => {
      if (chatId && chatId !== 'new' && chatId !== 'undefined' && filteredMessages?.length > 0) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      } else if (!chatId || chatId === 'new' || chatId === 'undefined') {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = 0;
        }
      }
    }, [chatId, filteredMessages?.length, scrollToBottom]);

  // Add this function to show custom toast
  const showCustomToast = useCallback((type, message) => {
    setToastType(type);
    setToastMessage(message);
    setShowToast(true);
    
    const timeoutId = setTimeout(() => setShowToast(false), 3000);
    
    // Store timeout ID for cleanup
    const currentTimeoutId = timeoutId;
    return () => clearTimeout(currentTimeoutId);
  }, []);


  // Ref to track processed messages
  const processedMessageIdsRef = useRef(new Set());

  const [showSalesModal, setShowSalesModal] = useState(false);
  const [salesModalFeature, setSalesModalFeature] = useState('');

    // Add new state for loading status
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    // Update the useEffect that handles messages
    useEffect(() => {
      if (chatId && chatId !== 'new' && messages) {
        // Short delay to ensure messages are properly loaded
        const timer = setTimeout(() => {
          setIsInitialLoading(false);
        }, 300); // Adjust timing as needed
  
        return () => clearTimeout(timer);
      } else if (chatId === 'new' || !chatId) {
        setIsInitialLoading(false);
      }
    }, [chatId, messages]);
  
  

  const closeSalesModal = () => {
    setShowSalesModal(false);
    setSalesModalFeature('');
  };


  const [searchEnable] = useState(() => {
    const savedFeatures = localStorage.getItem('betaFeatures');
    return savedFeatures ? JSON.parse(savedFeatures).searchConversations : false;
  });

  const editInputRef = useRef(null);

  const [isLoadingResource, setIsLoadingResource] = useState(false);

  const [spaceId, setSpaceId] = useState(null);

  const [isPDFProcessing, setIsPDFProcessing] = useState(false);

  // Add this new function to handle file drop
  const onDrop = useCallback((acceptedFiles) => {
    if (!user) {
      showCustomToast('error', 'Please sign in to upload files.');
      return;
    }

    const validFiles = acceptedFiles.filter(file => 
      ACCEPTED_FILE_TYPES[file.type] || file.type.startsWith('image/')
    );
    
    if (validFiles?.length > 0) {
      const newFiles = validFiles.slice(0, 3).map(file => ({
        id: `${file.name}-${file.size}-${Date.now()}`,
        name: file.name,
        file: file,
        type: file.type.startsWith('image/') ? 'image' : ACCEPTED_FILE_TYPES[file.type]
      }));
      setPdfFiles(prev => [...prev, ...newFiles]);
      showCustomToast('success', `${newFiles?.length} file(s) attached successfully.`);
    } else {
      showCustomToast('error', 'Only PDFs, images, and documents are allowed.');
    }
  }, [user, showCustomToast]);

  // Set up the dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
      'text/plain': ['.txt']
    },
    multiple: true,
    noClick: true,
    noKeyboard: true,
    disabled: !user,
  });

  useEffect(() => {
    if(filteredMessages?.length === 0) {
      setIsDefaultDisplay(true);
    } else {
      setIsDefaultDisplay(false);
    }
  }, [filteredMessages]);

  // Initialize suggestions
  useEffect(() => {
    const randomCategories = shuffle(categorizedSuggestions).slice(0, 3);
    const randomSuggestions = randomCategories.map(category => ({
      icon: category.icon,
      text: shuffle(category.suggestions)[0]
    }));
    setSuggestions(randomSuggestions);
  }, []);
  

  // Update remaining chats when initialRemainingChats changes
  useEffect(() => {
    setRemainingChats(initialRemainingChats);
  }, [initialRemainingChats]);

  // Add this new state for tracking if chat exists
  const [chatExists, setChatExists] = useState(true);

  const handleLogout = useCallback(() => {
    logout();
    navigate('/login');
  }, [logout, navigate]);

  // Fetch chats using react-query (move this up before messages query)
  const { data: chatsData, isSuccess: isChatsLoaded } = useQuery('chats', fetchChats, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    // Add enabled condition to prevent fetching when no user
    enabled: !!user,
  });

  useEffect(() => {
    if (chatsData?.chats) {
      const currentChat = chatsData.chats.find(chat => chat.id === chatId);
      const spaceIdFromParams = searchParams.get('spaceId');
      if (currentChat?.spaceId) {
        setSpaceId(currentChat.spaceId);
      } else if(isSpaceChat && spaceIdFromParams !== null) {
        const spaceIdFromParams = searchParams.get('spaceId');
        setSpaceId(spaceIdFromParams);
      }

      if(spaceId !== null) {
        // Check if we're on the correct path for space chats
        if (window.location.pathname.startsWith(`/`)) {
          navigate(`/ss/${chatId}?spaceId=${spaceId}`);
        }
      }
    }
  }, [chatsData, chatId, navigate, isSpaceChat, spaceId, searchParams]);

  // Update the messages query to check chats cache first
  const { data: messagesData, error: messagesError } = useQuery(
    ['messages', chatId],
    () => fetchMessages(chatId),
    {
      enabled: !!chatId && chatId !== 'new' && chatExists && !!user, // Add !!user condition
      staleTime: 5 * 60 * 1000, 
      cacheTime: 30 * 60 * 1000,
      retry: false,
      onSuccess: (data) => {
        setMessages(data);
        try {
          localStorage.setItem(`chat_${chatId}`, JSON.stringify(data));
        } catch (err) {
          // Error handling removed
        }
      },
      onError: (error) => {
        if (error.isAuthError) {
          localStorage.clear();
          queryClient.clear();
          navigate('/login');
        }
      }
    }
  );

  const handleResourceClick = useCallback((resourceTitle, messageId) => {
    setResources(currentResources => {
      if (!currentResources || !currentResources[chatId] || !Array.isArray(currentResources[chatId])) {
        return currentResources;
      }

      const foundResources = currentResources[chatId].filter(resource => resource.title === resourceTitle);
      
      if (foundResources?.length === 0) {
        return currentResources;
      }

      let selectedResource;

      if (messageId) {
        selectedResource = foundResources.find(resource => resource.messageId === messageId)
        if (!selectedResource) {
          selectedResource = foundResources.reduce((latest, current) => 
            ((current.version || 1) > (latest.version || 1)) ? current : latest
          );
        } 
      } else {
        selectedResource = foundResources.reduce((latest, current) => 
          ((current.version || 1) > (latest.version || 1)) ? current : latest
        );
      }

      setIsResourcesVisible(true);
      setIsResourcesExpanded(true);
      if (isResourcesVisible && !isResourcesExpanded) {
        setIsResourcesExpanded(true);
      } 

      setExpandedVersion(selectedResource.version || 0);
      setExpandedResource({
        ...selectedResource,
      });

      setSearchParams(prev => ({
        ...Object.fromEntries(prev),
        resource: resourceTitle,
        version: (selectedResource.version || 1).toString()
      }));

      return currentResources;
    });
  }, [chatId, setSearchParams, isResourcesVisible, isResourcesExpanded]); // Remove resources dependency


  // Update the useCallback for renderResource to remove resources dependency
  const renderResource = useCallback((resource, isLoading = false) => (
    <div className="resource-item-container">
      <div className={`resource-item`} 
           onClick={() => !resource.isLoading && handleResourceClick(resource.title, resource.messageId)}>
        <span className="resource-icon">
          {isLoading ? (
            <div className="resource-loading-spinner" />
          ) : (
            getResourceIcon(resource.type)
          )}
        </span>
        <div className="resource-info">
          <h3 className="resource-title">
            {resource.title || (isLoading ? 'Generating Resource...' : '')}
          </h3>
          <span className="resource-type">
            {((isLoading && isGeneratingResponse) ? 'Please wait' : 'Click to Open Resource')}
          </span>
        </div>
        {!isLoading && <FaChevronRight className="resource-arrow" />}
      </div>
      {resource.content && isLoading && (
        <div className="resource-streaming">
          <span>
            {resource.content.replace(/<(artifacts|code|essay|quote|visualization)>/g, '[').replace(/<\/(artifacts|code|essay|quote|visualization)>/g, ']').slice(-300)}
          </span>
        </div>
      )}
    </div>
  ), [handleResourceClick, isGeneratingResponse]); // Remove resources dependency

  // Update handleResourceClick to use resources from state directly
  
  // Add this effect to check chats cache
  useEffect(() => {
    if (isChatsLoaded && chatId && chatId !== 'new') {
      const chatExists = chatsData?.chats?.some(chat => chat.id === chatId);
      setChatExists(chatExists);
    }
  }, [isChatsLoaded, chatsData, chatId]);

  // Start editing a message
  const handleEditStart = useCallback((message) => {
    setEditedMessageId(message.id);
    setEditingMessageId(message.id);
    setEditContent(message.content);
    setTimeout(() => {
      if (editInputRef.current) {
        editInputRef.current.style.height = 'auto';
        editInputRef.current.style.height = `${editInputRef.current.scrollHeight}px`;
      }
    }, 100);
  }, []);
  
  useEffect(() => {
    if (editingMessageId && editInputRef.current) {
      editInputRef.current.style.height = 'auto';
      editInputRef.current.style.height = `${editInputRef.current.scrollHeight}px`;
      editInputRef.current.focus();
    }
  }, [editingMessageId]);

  // Cancel editing a message
  const handleEditCancel = useCallback(() => {
    setEditingMessageId(null);
    setEditedMessageId(null);
    setEditContent('');
  }, []);

  // Update the handleCreateResource function 
  const handleCreateResource = useCallback(async (newResource) => {
    setResources(prevResources => {
      const updatedResources = {
        ...prevResources,
        [chatId]: prevResources[chatId] || []
      };

      // Find existing resources with same title and type
      const sameResources = updatedResources[chatId].filter(r => 
        r.title === newResource.title && 
        r.type === newResource.type
      );

      // Find highest version number
      const highestVersion = sameResources.reduce((max, r) => 
        Math.max(max, r.version || 0), 0);

      // Set the new resource version
      newResource.version = highestVersion + 1;

      // Add the new resource
      updatedResources[chatId] = [...updatedResources[chatId], newResource];

      return updatedResources;
    });
  }, [chatId]);

  const processArtifacts = useCallback((content, id, flag) => {
    let newResources = [];
    const artifactsMatch = content.match(/<artifacts>([\s\S]*?)<\/artifacts>/g);
    
    if (artifactsMatch) {
      artifactsMatch.forEach((artifact, index) => {
        const isUnclosed = !artifact.includes('</artifacts>');
        
        // Handle unclosed artifacts as loading resources
        if (isUnclosed && !flag) {
          const resourceId = `resource-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
          const content = artifact.replace('<artifacts>', '').trim();
          
          // Try to parse any structured content
          const essayMatch = content.match(/<essay>[\s\S]*?<title>(.*?)<\/title>/);
          const codeMatch = content.match(/<code.*?language="(.*?)".*?title="(.*?)">/);
          
          let newResource;
          if (essayMatch) {
            newResource = {
              id: resourceId,
              type: 'essay',
              title: essayMatch[1] || 'Untitled Essay',
              content: content.replace(/<essay>[\s\S]*?<title>.*?<\/title>/, ''),
              timestamp: new Date().toISOString(),
              messageId: id
            };
          } else if (codeMatch) {
            newResource = {
              id: resourceId, 
              type: 'code',
              language: codeMatch[1],
              title: codeMatch[2] || 'Untitled Code',
              content: content.replace(/<code.*?>/, ''),
              timestamp: new Date().toISOString(),
              messageId: id
            };
          } else {
            // Generic resource if no specific type detected
            newResource = {
              id: resourceId,
              type: 'generic',
              title: 'Generated Resource',
              content: content,
              timestamp: new Date().toISOString(),
              messageId: id
            };
          }
          
          if(!flag) {
            handleCreateResource(newResource);
          }
          if(newResource !== undefined) {
            newResources.push(newResource);
          }
          return;
        }

        // Parse essay artifacts
        const essayMatch = artifact.match(/<essay>([\s\S]*?)<\/essay>/);
        if (essayMatch) {
          const essayContent = essayMatch[1];
          const titleMatch = essayContent.match(/<title>(.*?)<\/title>/);
          const contentMatch = essayContent.match(/<content>([\s\S]*?)<\/content>/);
          
          if (titleMatch && contentMatch) {
            const resourceId = `essay-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'essay',
              title: titleMatch[1],
              content: contentMatch[1],
              timestamp: new Date().toISOString(),
              messageId: id
            };
            
            if(!flag) {
              handleCreateResource(newResource);
            }
            newResources.push(newResource);
            return;
          }
        }

        // Parse code artifacts
        const codeMatch = !artifact.includes('<essay>') ? artifact.match(/<code.*?>([\s\S]*?)<\/code>/) : null;
        if (codeMatch) {
          const codeContent = codeMatch[1];
          const languageMatch = codeMatch[0].match(/language="(.*?)"/);
          const titleMatch = codeMatch[0].match(/title="(.*?)"/);
          
          if (languageMatch && titleMatch) {
            const resourceId = `code-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'code',
              language: languageMatch[1],
              title: titleMatch[1],
              content: codeContent,
              timestamp: new Date().toISOString(),
              messageId: id
            };
            
            if(!flag) {
              handleCreateResource(newResource);
            }
            newResources.push(newResource);
          }
        }

        // Parse visualization artifacts
        const vizMatch = !artifact.includes('<essay>') ? artifact.match(/<visualization.*?>([\s\S]*?)<\/visualization>/) : null;
        if (vizMatch) {
          const vizContent = vizMatch[1];
          const titleMatch = vizMatch[0].match(/title="(.*?)"/);
          
          if (titleMatch) {
            const resourceId = `viz-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'visualization',
              title: titleMatch[1],
              content: vizContent,
              timestamp: new Date().toISOString(),
              messageId: id
            };
            
            if(!flag) {
              handleCreateResource(newResource);
            }
            newResources.push(newResource);
          }
        }
      });
    }

    if(flag) {
      // Update resources with latest versions
      setResources(prevResources => {
        const updatedResources = { ...prevResources };
        if (!updatedResources[chatId]) {
          updatedResources[chatId] = [];
        }

        newResources.forEach(newResource => {
          // Find existing resources with same title and type
          const sameResources = updatedResources[chatId].filter(r => 
            r.title === newResource.title && 
            r.type === newResource.type
          );

          // Find highest version number
          const highestVersion = sameResources.reduce((max, r) => 
            Math.max(max, r.version || 0), 0);

          // Set the new resource version
          newResource.version = highestVersion + 1;

          // Add the new resource
          updatedResources[chatId].push(newResource);
        });

        return updatedResources;
      });
    }
    return newResources;
  }, [chatId, handleCreateResource]);

  const appendChunk = (chunk) => {
    setGeneratedContent(prev => prev + chunk);
  };

  const handleRegenerateMessage = useCallback(async (messageId) => {
    setRegeneratingMessageId(messageId);

    // Clear expanded resource if it was from this message
    if (expandedResource?.messageId === messageId) {
      setExpandedResource(null);
      setIsResourcesExpanded(false);
      
      // Clear resource-related URL params
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('resource');
      newParams.delete('version');
      setSearchParams(newParams);
    }

    // Clear resources for this message
    setResources(prevResources => {
      const updatedResources = { ...prevResources };
      Object.keys(updatedResources).forEach(chatId => {
        updatedResources[chatId] = updatedResources[chatId].filter(
          resource => resource.messageId !== messageId
        );
      });
      return updatedResources;
    });

    // Store original message content before clearing
    const originalMessage = messages.find(msg => msg.id === messageId);
    const originalContent = originalMessage?.content;

    // Clear message content to prepare for streaming
    setMessages(prevMessages => 
      prevMessages.map(msg => 
        msg.id === messageId 
          ? { ...msg, content: '' }
          : msg
      )
    );

    try {
      const { stream } = await regenerateMessage(chatId, messageId, null, currentMode);
      let accumulatedResponse = '';

      for await (const chunk of stream) {
        if (chunk.content) {
          const text = chunk.content;
          const currentResponse = accumulatedResponse + text;
          
          setMessages(prevMessages => prevMessages.map(msg => {
            if (msg.id === messageId) {
              return { ...msg, content: currentResponse };
            }
            return msg;
          }));

          accumulatedResponse = currentResponse;

          for (let i = 0; i < text?.length; i++) {
            appendChunk(text[i]);
          }
        }
      }

      // Process artifacts after streaming completes
      processArtifacts(accumulatedResponse, messageId, false);
      
      // Cleanup
      setRegeneratingMessageId(null);
      setIsGeneratingResponse(false);
      
      // Refresh messages to ensure consistency
      queryClient.invalidateQueries(['messages', chatId]);

    } catch (error) {
      console.error('Regeneration error:', error);
      showCustomToast('error', 'Failed to regenerate message. Please try again.');
      
      // Restore original message content
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === messageId
            ? { ...msg, content: originalContent }
            : msg
        )
      );
      
      // Cleanup on error
      setRegeneratingMessageId(null);
      setIsGeneratingResponse(false);
    }
  }, [
    chatId,
    messages,
    searchParams,
    setSearchParams,
    expandedResource,
    currentMode,
    queryClient,
    processArtifacts,
    showCustomToast,
  ]);

  // Update the groupedMessages useMemo
  const groupedMessages = useMemo(() => {
    if (!Array.isArray(messages)) {
      return [];
    }

    const groups = {};
    const validMessages = messages.filter(message => message != null);

    validMessages.forEach(message => {
      // Generate a unique key for each message
      const messageKey = `${message.id}-${message.timestamp}`;
      
      if (message.parent_message_id) {
        if (!groups[message.parent_message_id]) {
          groups[message.parent_message_id] = [];
        }
        groups[message.parent_message_id].push({
          ...message,
          uniqueKey: messageKey
        });
      } else {
        if (!groups[message.id]) {
          groups[message.id] = [{
            ...message,
            uniqueKey: messageKey
          }];
        }
      }
    });
  
    return Object.values(groups);
  }, [messages]);

  // Update the search state to include chat information
  const [searchResults, setSearchResults] = useState([]);

  // Add debounced search function using useCallback and useRef
  const searchTimeoutRef = useRef(null);
  const debouncedSearch = useCallback((query) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    searchTimeoutRef.current = setTimeout(() => {
      const lowercasedQuery = query.toLowerCase();
      const results = [];
      
      // Search current chat messages
      const currentChatResults = messages.filter(message =>
        message.content.toLowerCase().includes(lowercasedQuery)
      ).map(message => ({
        ...message,
        chatId: chatId,
        chatTitle: currentChatTitle || 'Current Chat',
        preview: message.content.substring(0, 150) + '...'
      }));

      results.push(...currentChatResults);

      // Search localStorage for other chats
      for (let i = 0; i < localStorage?.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith('chat_')) {
          try {
            const chatMessages = JSON.parse(localStorage.getItem(key));
            const chatId = key.replace('chat_', '');
            
            // Find chat title from chatsData
            const chatInfo = chatsData?.chats?.find(chat => chat.id === chatId);
            const chatTitle = chatInfo?.title || 'Untitled Chat';

            // Search through messages
            const matchingMessages = chatMessages.filter(message =>
              message.content.toLowerCase().includes(lowercasedQuery)
            ).map(message => ({
              ...message,
              chatId,
              chatTitle,
              preview: message.content.substring(0, 150) + '...'
            }));

            results.push(...matchingMessages);
          } catch (error) {
            // Silent error handling for corrupted localStorage data
          }
        }
      }

      // Remove duplicates based on message ID
      const uniqueResults = Array.from(
        new Map(results.map(item => [item.id, item])).values()
      );

      // Sort results by timestamp (most recent first)
      uniqueResults.sort((a, b) => 
        new Date(b.timestamp) - new Date(a.timestamp)
      );

      // Set filtered messages for current chat display
      const currentChatFiltered = uniqueResults.filter(
        result => result.chatId === chatId
      );
      setFilteredMessages(currentChatFiltered);

      // Set search results for all chats
      setSearchResults(uniqueResults);
    }, 300); // 300ms delay

    // Add cleanup to the callback
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [messages, chatId, currentChatTitle, chatsData]);

  // Create a debounced search handler outside the component
  const MobileSearchOverlay = ({ isOpen, onClose, searchQuery, onSearch, searchResults, onResultClick }) => {
    // Local state for input value
    const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);

    // Memoize the debounced search handler
    const debouncedSearch = useMemo(
      () => debounce((value) => {
        onSearch({ target: { value } });
      }, 300),
      [onSearch]
    );

    // Handle input change
    const handleInputChange = useCallback((e) => {
      const value = e.target.value;
      setLocalSearchQuery(value);
      debouncedSearch(value);
    }, [debouncedSearch]);

    // Memoize the search results rendering
    const renderSearchResults = useMemo(() => {
      if (searchResults?.length > 0) {
        return searchResults.map((result, index) => (
          <div
            key={result.id}
            className="search-result-item"
            onClick={() => onResultClick(result.id)}
            style={{ animationDelay: `${index * 0.05}s` }}
          >
            <h3>{result.title || 'Untitled Chat'}</h3>
            <p>{result.preview}</p>
          </div>
        ));
      }
      
      if (localSearchQuery) {
        return <div className="no-results">No conversations found</div>;
      }
      
      return <div className="search-prompt">Start typing to search your conversations</div>;
    }, [searchResults, localSearchQuery, onResultClick]);

    // Cleanup debounce on unmount
    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    // Early return after all hooks
    if (!isOpen) return null;

    return (
      <div 
        className="mobile-search-overlay" 
        onClick={(e) => e.target === e.currentTarget && onClose()}
      >
        <div className="mobile-search-header">
          <input
            type="text"
            placeholder="Search conversations..."
            value={localSearchQuery}
            onChange={handleInputChange}
            autoFocus
            className="mobile-search-input"
          />
          <button 
            onClick={onClose} 
            className="mobile-search-close"
            aria-label="Close search"
          >
            <X size={24} />
          </button>
        </div>
        <div className="mobile-search-results">
          {renderSearchResults}
        </div>
      </div>
    );
  };

  // Update the handleSearch function
  const handleSearch = useCallback((e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    if (!value.trim()) {
      setSearchResults([]);
      setFilteredMessages([]);
      return;
    }

    debouncedSearch(value);
  }, [debouncedSearch]);

  // Add this component for the search results from other chats
  const OtherChatsSearchResults = ({ results, onResultClick }) => {
    if (!results?.length) return null;

    return (
      <div className="other-chats-results">
        <div className="other-chats-header">Search Results from Other Chats</div>
        {results.map((result) => (
          <div
            key={result.id}
            className="other-chat-result"
            onClick={() => onResultClick(result.chatId)}
          >
            <div className="result-chat-title">
              <MessageSquare size={16} />
              {result.chatTitle || ''}
            </div>
            <div className="result-preview">
              <AIResponseRenderer content={result.preview?.length > 100 
                ? `${result.preview.substring(0, 100)}...` 
                : result.preview} 
                renderResource={renderResource} 
                messageId={result.id}
              />
            </div>
            <ChevronRight className="preview-arrow" size={16} />
          </div>
        ))}
      </div>
    );
  };
  



  // Add handler for search result clicks
  const handleSearchResultClick = useCallback((chatId) => {
    setSearchQuery('');
    setSearchResults([]);
    navigate(`/${chatId}`);
  }, [navigate]);

  // Add a new chat
  const handleAddChat = useCallback(async () => {
    try {
      setMessages([]);
      setInput('');
      setFilteredMessages([]);
      setIsDefaultDisplay(true);
      setChatIdToUse(null);
      navigate('/', { replace: true });
    } catch (error) {
      showCustomToast('error', 'Failed to create a new chat. Please try again.');
    }
  }, [navigate, showCustomToast]);


  // Load messages from cache or fetch new ones
  useEffect(() => {
    if (chatId && chatId !== 'new') {
      const cachedMessages = localStorage.getItem(`chat_${chatId}`);
      if (cachedMessages && cachedMessages !== 'undefined') { // Added check
        try {
          const parsedMessages = JSON.parse(cachedMessages);
          setMessages(parsedMessages);
        } catch (error) {
          // Removed console.error
          // Optionally, remove the corrupted cache
          localStorage.removeItem(`chat_${chatId}`);
          // Fallback to messagesData or an empty array
          setMessages(messagesData || []);
        }
      } else if (messagesData) {
        setMessages(messagesData);
      }
    } else if (chatId === 'new') {
      setMessages([]);
    }
  }, [chatId, messagesData]);

  // Auto-scroll to the latest message
  useEffect(() => {
    if (autoScroll && lastMessageRef.current && !isGeneratingResponse) {
      const scrollOptions = {
        behavior: 'smooth',
        block: 'end',
      };

      // Use requestAnimationFrame to ensure DOM has updated
      requestAnimationFrame(() => {
        lastMessageRef.current?.scrollIntoView(scrollOptions);
      });

      setAutoScroll(false);
    }
  }, [autoScroll, lastMessageRef, isGeneratingResponse]);

    // Add this effect to handle guest chat reset
    useEffect(() => {
      if (!user) {
        const lastReset = localStorage.getItem('guestChatsLastReset');
        const today = new Date().toDateString();
  
        if (!lastReset || lastReset !== today) {
          // Reset guest chats at the start of a new day
          setRemainingGuestChats(3);
          localStorage.setItem('guestRemainingChats', '3');
          localStorage.setItem('guestChatsLastReset', today);
        }
      }
    }, [user]);

  // Update the useEffect for window resize
  useEffect(() => {
    const savedSidebarState = localStorage.getItem('isSidebarOpen');
    if (savedSidebarState !== null) {
      setIsSidebarOpen(JSON.parse(savedSidebarState));
    }
    
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      
      // Close sidebar and hover state when transitioning to mobile
      if (newWidth <= 768) {
        setIsSidebarOpen(false);
        setIsHoverOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Initial check on mount
    handleResize();
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Add a separate useEffect for setting the initial dynamic header
  useEffect(() => {
    setDynamicHeader(getRandomHeader(user?.name));
  }, [user?.name]); // Only regenerate when user name changes

  // Update the handleUpdateTitle function
  const handleUpdateTitle = useCallback((newTitle) => {
    setCurrentChatTitle(newTitle);
  }, []);

  // Update chatIdToUse based on chatId
  useEffect(() => {
    if (chatId === 'new') {
      setChatIdToUse(null);
      setMessages([]);
      setFilteredMessages([]);
      setCurrentChatTitle('');
    } else if (chatId) {
      setChatIdToUse(chatId);
    }
  }, [chatId]);

const handleImprove = async (message) => {
  setInput(message);
  inputRef.current = message;
  // Wait for state to update before submitting
  await new Promise(resolve => setTimeout(resolve, 0));
  handleSubmit({ preventDefault: () => {} });
};

const handleExplain = async (message) => {
  setInput(message);
  inputRef.current = message;
  // Wait for state to update before submitting
  await new Promise(resolve => setTimeout(resolve, 0));
  handleSubmit({ preventDefault: () => {} });
};

  // Update the processArtifacts function
  

  const handleTextareaInput = (event) => {
    const textarea = event.target;
    textarea.style.height = 'auto'; // Reset height first
    const newHeight = Math.max(textarea.scrollHeight, textarea.offsetHeight);
    textarea.style.height = `${newHeight}px`;
  };


  useEffect(() => {
    // Skip if no chats data
    if (!chatsData?.chats?.length) return;

    // Create a temporary object to store all resources
    const newResources = {};

    // Process each chat's resources
    chatsData.chats.forEach(chat => {
      if (!chat?.pdf_content) return;

      // Initialize array for this chat's resources
      newResources[chat.id] = [];

      try {
        // Split PDF content by sections
        const sections = chat.pdf_content.split(/(?=<(?:PDF|DOC) START>)/);
        
        sections.forEach((section, index) => {
          if (!section.trim()) return;

          // Extract document type and title
          const typeMatch = section.match(/<(PDF|DOC) START>/);
          const titleMatch = section.match(/\[(?:PDF|DOC) TITLE:\s*(.*?)\]/);
          
          if (!typeMatch || !titleMatch) return;

          const docType = typeMatch[1].toLowerCase();
          const title = titleMatch[1].trim() || `Untitled ${docType.toUpperCase()} ${index + 1}`;

          // Extract content
          const contentMatch = section.match(/\]([^[<]*?)(?:<(?:PDF|DOC) END>|$)/s);
          if (!contentMatch) return;

          const content = contentMatch[1].trim();
          if (!content) return;

          // Create new resource
          const resourceId = `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
          newResources[chat.id].push({
            id: resourceId,
            title,
            content,
            type: 'file',
            docType,
            timestamp: new Date().toISOString()
          });
        });

      } catch (error) {
        console.error('Error processing PDF content:', error);
      }
    });

    // Update resources state in a single batch
    setResources(prevResources => {
      const mergedResources = { ...prevResources };
      
      Object.entries(newResources).forEach(([chatId, chatResources]) => {
        if (!mergedResources[chatId]) {
          mergedResources[chatId] = [];
        }
        
        // Add only new resources
        chatResources.forEach(newResource => {
          const exists = mergedResources[chatId].some(
            existing => existing.title === newResource.title && existing.type === 'file'
          );
          
          if (!exists) {
            mergedResources[chatId].push(newResource);
          }
        });
      });
      
      return mergedResources;
    });



  }, [chatsData, chatId]); // Remove resources from dependencies

// Separate useEffect for processing message artifacts
useEffect(() => {
  if (!messages) return;

  messages.forEach(message => {
    if (!processedMessageIdsRef.current.has(message?.id)) {
      if (message?.is_ai) {
        processArtifacts(message.content, message.id);
      } else if (message?.imagestitle) {
        try {
          const imageTitles = JSON.parse(message.imagestitle);
          const images = JSON.parse(message.images);

          imageTitles.forEach((title, i) => {
            const resourceId = `image-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            handleCreateResource({
              id: resourceId,
              type: 'image',
              title,
              content: `Image file: ${title}`,
              timestamp: new Date().toISOString(),
              messageId: message.id,
              url: images[i].url
            });
          });
        } catch (error) {
          // Silent error handling
        }
      }
      processedMessageIdsRef.current.add(message.id);
    }
  });
}, [messages, processArtifacts, handleCreateResource, chatId]);

const restoreInput = useCallback((content) => {
  setInput(content);
  inputRef.current = content;
  if (textareaRef.current) {
    textareaRef.current.value = content;
  }
}, []);

// Remove temporary messages in case of error
const removeTemporaryMessages = useCallback((userMessageId, aiMessageId) => {
  setMessages(prevMessages => 
    prevMessages.filter(msg => msg.id !== userMessageId && msg.id !== aiMessageId)
  );
}, []);

const cleanupSubmission = useCallback(() => {
  setIsInputProcessing(false);
  setIsGeneratingResponse(false); // Only need to reset one state
  setIsSubmitting(false);
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
    abortControllerRef.current = null;
  }
}, []);

const handleSubmissionError = useCallback((error, currentInput, userMessageId, currentPdfFiles) => {
  removeTemporaryMessages(userMessageId);
  restoreInput(currentInput);
  setPdfFiles(currentPdfFiles); // Restore PDF file
  if (error.name === 'AbortError') {
  } else {
    showCustomToast('error', 'An error occurred while sending your message. Please try again.');
  }
}, [removeTemporaryMessages, restoreInput, setPdfFiles, showCustomToast]);

const [cutMessages, setCutMessages] = useState([]);

useEffect(() => {
  // Check for change in remainingGuestChats
  if (remainingGuestChats === 0) {
    const chatState = {
      inputValue: inputRef.current,
      attachments: pdfFiles || []
    };
    localStorage.setItem('pendingChatState', JSON.stringify(chatState));
    setShowAuthPrompt(true);
  }
}, [remainingGuestChats, pdfFiles]);

const handleSubmit = useCallback(async (e) => {
    if (e) e.preventDefault();
    
    // At the start of submission, disable auto-scroll
    setAutoScroll(false);
    
    // Get current remaining chats based on user status
    const currentRemaining = user ? remainingChats : remainingGuestChats;

    // Check remaining chats for guest users
    if (!user && currentRemaining <= 0) {
      const chatState = {
        inputValue: inputRef.current,
        attachments: pdfFiles || []
      };
      
      localStorage.setItem('pendingChatState', JSON.stringify(chatState));
      setShowAuthPrompt(true);
      return;
    }
    
    // Check remaining chats for logged-in users
    if (user && currentRemaining <= 0 && currentRemaining !== null) {
      showCustomToast('error', 'No remaining chats. Please upgrade to continue.');
      setShowSalesModal(true);
      setSalesModalFeature('chats');
      return;
    }

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setIsGeneratingResponse(true); // Single state for generation status
    setGeneratedContent('');
    
    if (editedMessageId == null) {
      if (
        (!inputRef.current.trim() && (!pdfFiles || pdfFiles?.length === 0)) ||
        (currentRemaining <= 0 && currentRemaining != null) ||
        isInputProcessing
      ) {
        setIsSubmitting(false);
        setIsGeneratingResponse(false);
        return;
      }
    }

    const currentInput = inputRef.current;
    const currentTimestamp = new Date().toISOString();
    let tempUserMessageId = generateTempId();
    const tempAiMessageId = generateTempId();

    let userMessage;
    let currentPdfFiles = [];

    // Safely handle file arrays with null checks
    const fileArray = Array.isArray(pdfFiles) ? pdfFiles.map(pdf => pdf?.file).filter(Boolean) : [];
    
    // Safely filter PDF files
    const actualpdfFiles = fileArray.filter(file => 
      file && file.type && !file.type.startsWith('image/')
    );
    // Safely create image titles array
    const imageFiles = fileArray.filter(file => 
      file && file.type && file.type.startsWith('image/')
    );
    
    const imageTitles = JSON.stringify(
      imageFiles.map(file => file.name || 'Untitled')
    );

    let toSwitch = null;

    try {
      if (editedMessageId == null) {
        userMessage = {
          id: tempUserMessageId,
          content: currentInput,
          is_ai: false,
          role: 'user',
          timestamp: currentTimestamp,
          used_pdf: pdfFiles?.length > 0,
          pdf_title: actualpdfFiles?.length > 0 ? JSON.stringify(actualpdfFiles.map(file => file?.name)) : null,
          imagestitle: imageTitles?.length > 0 ? imageTitles : null
        };

        currentPdfFiles = actualpdfFiles || [];
        setPdfFiles([]);
        setMessages(prevMessages => [...prevMessages, userMessage]);
        setIsGeneratingResponse(true);
      } else {
        setMessages(prevMessages => {
          const editingIndex = prevMessages.findIndex(msg => msg.id === editingMessageId);
          if (editingIndex === -1) return prevMessages;
          
          const updatedMessages = prevMessages.slice(0, editingIndex + 1);
          updatedMessages[editingIndex] = {
            ...updatedMessages[editingIndex],
            content: editContent
          };
          return updatedMessages;
        });

        tempUserMessageId = editedMessageId;
      }

      setIsInputProcessing(true);
    } catch (error) {
      console.error('Error processing files:', error);
      showCustomToast('error', 'Error processing files. Please try again.');
      setIsSubmitting(false);
      setIsGeneratingResponse(false);
      return;
    }

    setInput('');
    try {
      setIsInputProcessing(true);
      let currentChatId = chatIdToUse;
      
      if (user) {
        if (!currentChatId || currentChatId === 'new' || currentChatId === 'undefined') {
          let newChat;
          if (spaceId) {
            newChat = await createSpaceChat(spaceId);
            queryClient.refetchQueries('space-chats');
          } else {
            newChat = await createNewChat();
          }
          localStorage.removeItem('draft_new');
          localStorage.removeItem(`draft_${newChat.id}`); // Fixed template literal syntax
          toSwitch = newChat.id;
          currentChatId = newChat.id;
          setChatIdToUse(currentChatId);

          if (!isSpaceChat) {
            queryClient.setQueryData('chats', (oldData) => {
              if (!oldData) return { chats: [] };
              return {
                ...oldData,
                chats: [{
                  id: currentChatId,
                  title: 'New Chat',
                  last_modified: new Date().toISOString(),
                }, ...oldData.chats]
              };
            });
          }
        }
      }

      scrollToBottom();
      inputRef.current = '';

      const controller = new AbortController();
      abortControllerRef.current = controller;

      const formattedContext = messages.map(msg => ({
        role: msg.is_ai ? 'assistant' : 'user',
        content: msg.content
      }));
      
      let usedWeb = false;
      let usedPDF = false;
      let userMessageId = null;
      let aiMessageId = null;
      let newTitle = null;

      const aiMessage = {
        id: tempAiMessageId,
        content: '',
        is_ai: true,
        role: 'assistant',
        timestamp: new Date().toISOString(),
        used_web: user ? usedWeb : false,
      };

      if (actualpdfFiles?.length > 0) {
        setIsPDFProcessing(true);
      }

      let stream, response;
      if (editedMessageId) {
        ({ stream, response } = await editMessageActual(
          currentChatId, 
          editedMessageId, 
          currentInput, 
          currentMode
        ));
        setMessages(prevMessages => {
          const editingIndex = prevMessages.findIndex(msg => msg.id === editedMessageId);
          if (editingIndex === -1) return prevMessages;
          
          // Save cut messages to array
          setCutMessages(prevMessages.slice(editingIndex + 1));
          
          return prevMessages.slice(0, editingIndex + 1);
        });
      } else {
        ({ stream, response } = user 
          ? await sendMessage(currentChatId, currentInput, currentMode, controller.signal, fileArray)
          : await sendGuestMessage(formattedContext, currentInput, currentMode));
      }

      let imgUrls = [];
      try {
        imgUrls = JSON.parse(response.headers.get('X-Image-Urls') || '[]');
      } catch (error) {
        console.error('Error parsing image URLs:', error);
        imgUrls = [];
      }

      let modelUsed = '';

      if (user) {
        usedWeb = response.headers.get('X-Used-Web') === 'true';
        usedPDF = response.headers.get('X-Used-PDF') === 'true';
        userMessageId = response.headers.get('X-User-Message-Id');
        aiMessageId = response.headers.get('X-AI-Message-Id');
        newTitle = response.headers.get('X-New-Title');
        imgUrls = JSON.parse(response.headers.get('X-Image-Urls') || '[]');

        let pdfTitles = JSON.stringify(actualpdfFiles.map(pdf => pdf.name));

        if (!editedMessageId) {
          setMessages(prevMessages => {
            const messageIndex = prevMessages.findIndex(msg => msg.id === tempUserMessageId);
            if (messageIndex === -1) return prevMessages;
            
            const updatedMessages = [...prevMessages];
            updatedMessages[messageIndex] = {
              ...updatedMessages[messageIndex],
              id: userMessageId,
              used_pdf: usedPDF,
              pdf_title: pdfTitles,
              imagestitle: imageTitles?.length > 0 ? imageTitles : null
            };
            return updatedMessages;
          });
        }
      } else {
        modelUsed = response.headers.get('X-Model');
      }

      let accumulatedResponse = '';
      let initialEXPopen = false;

      for await (const chunk of stream) {
        setIsPDFProcessing(false);
        if (chunk.content) {
          const text = chunk.content;
          const currentResponse = accumulatedResponse + text;
          
          setMessages(prevMessages => prevMessages.map(msg => {
            if (msg.is_ai && (
              (editedMessageId && msg.id === editedMessageId) || 
              (!editedMessageId && msg.id === tempAiMessageId)
            )) {
              return { ...msg, content: currentResponse };
            }
            return msg;
          }));

          // Update accumulated response after the map
          accumulatedResponse = currentResponse;

          for (let i = 0; i < text?.length; i++) {
            appendChunk(text[i]);
          }

          const openingTags = (accumulatedResponse.match(/<artifacts>/g) || [])?.length;
          const closingTags = (accumulatedResponse.match(/<\/artifacts>/g) || [])?.length;
          if (openingTags > closingTags) {
            setIsResourcesVisible(true);
            setIsLoadingResource(true);
          } else {
            setIsLoadingResource(false);
          }

          if (isLoadingResource && Math.random() < 4/6) {
            if (expandedResource !== null) {
              if (!initialEXPopen) {
                setIsResourcesVisible(true);
              }
              const newResources = processArtifacts(generatedContent, null, true);
              setIsResourcesExpanded(true);
              
              if (newResources && newResources?.length > 0) {
                const latestResource = newResources.reduce((latest, current) => {
                  return !latest || new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
                }, null);
                setExpandedResource(latestResource);
              } else {
                setExpandedResource(null);
              }
            }
          }
        }

        if (accumulatedResponse.endsWith("[Generation stopped]") || chunk.content.includes('{"status": "complete"}')) {
          break;
        }
      }

      setIsLoadingResource(false);
      setIsGeneratingResponse(false);

      // Final message update
      if (!user) {
        setMessages(prevMessages => {
          const messageIndex = prevMessages.findIndex(msg => msg.id === tempUserMessageId);
          if (messageIndex === -1) {
            // If the user message doesn't exist, add both messages
            return [
              ...prevMessages,
              {
                id: tempUserMessageId,
                content: currentInput,
                is_ai: false,
                role: 'user',
                timestamp: currentTimestamp
              },
              {
                id: tempAiMessageId,
                content: accumulatedResponse,
                is_ai: true,
                role: 'assistant',
                model: modelUsed
              }
            ];
          }
          
          // If the user message exists, update it and add the AI response
          const updatedMessages = [...prevMessages];
          updatedMessages[messageIndex] = {
            ...updatedMessages[messageIndex],
            content: currentInput
          };
          return [
            ...updatedMessages,
            {
              id: tempAiMessageId,
              content: accumulatedResponse,
              is_ai: true,
              role: 'assistant',
              model: modelUsed
            }
          ];
        });
      } else {
        
        if (editedMessageId) {
          // For edited messages, just update the content and add the AI response
          setMessages(prevMessages => {
            const editingIndex = prevMessages.findIndex(msg => msg.id === editedMessageId);
            if (editingIndex === -1) return prevMessages;
            
            const updatedMessages = prevMessages.slice(0, editingIndex + 1);
            updatedMessages[editingIndex] = {
              ...updatedMessages[editingIndex],
              content: editContent
            };
            
            // Add the new AI response with accumulated content
            return [...updatedMessages, {
              ...aiMessage,
              id: aiMessageId || tempAiMessageId,
              content: accumulatedResponse,
              used_web: usedWeb,
              timestamp: new Date().toISOString()
            }];
          });
        } else {
          // Existing code for new messages
          if (!userMessageId || !aiMessageId) {
            setMessages(prevMessages => {
              // Remove any existing temporary messages
              const filteredMessages = prevMessages.filter(msg => 
                msg.id !== tempUserMessageId && 
                msg.id !== tempAiMessageId
              );
              
              return [...filteredMessages, userMessage, aiMessage];
            });
            
            return;
          }

          const finalUserMessage = {
            ...userMessage,
            id: userMessageId,
            content: currentInput,
            role: 'user'
          };

          const finalAIMessage = {
            ...aiMessage,
            id: aiMessageId,
            content: accumulatedResponse
          };

          // Replace temporary messages with final ones
          setMessages(prevMessages => {
            const filteredMessages = prevMessages.filter(msg => 
              msg.id !== tempUserMessageId && 
              msg.id !== tempAiMessageId
            );

            return [...filteredMessages, finalUserMessage, finalAIMessage];
          });

          // Update query cache
          queryClient.setQueryData(['messages', currentChatId], (oldData) => {
            if (!oldData) return [finalAIMessage];
            
            const filteredOldData = oldData.filter(msg => 
              msg.id !== tempUserMessageId && 
              msg.id !== tempAiMessageId
            );
            
            return [...filteredOldData, finalUserMessage, finalAIMessage];
          });

          // Update the chat title if a new title was received
          if (newTitle) {
            if (isSpaceChat) {
              // Update space-chats query data
              queryClient.setQueryData('space-chats', (oldData) => {
                if (!oldData?.chats) return oldData;
                
                return {
                  ...oldData,
                  chats: oldData.chats.map(chat => 
                    chat.id === currentChatId 
                      ? { ...chat, title: newTitle }
                      : chat
                  )
                };
              });
            } else {
              // Update the current chat title in the component state
              setCurrentChatTitle(newTitle);
              handleUpdateTitle(newTitle);

              // Update the chat list in the sidebar with the new title
              queryClient.setQueryData('chats', (oldData) => {
                if (!oldData || !oldData.chats) return { chats: [] };
                
                const updatedChats = oldData.chats.filter(chat => chat.id !== currentChatId);
                return {
                  ...oldData,
                  chats: [{
                    id: currentChatId,
                    title: newTitle,
                    last_modified: new Date().toISOString(),
                  }, ...updatedChats]
                };
              });
            }
          }
        }
      }

      // Update remaining chats for guest users
      if (remainingChats !== null) {
        if (!user) {
          // Update guest chats in both state and localStorage
          const newRemainingChats = remainingGuestChats - 1;
          setRemainingGuestChats(newRemainingChats);
          localStorage.setItem('guestRemainingChats', newRemainingChats.toString());
          
          // Update last reset date if needed
          if (!localStorage.getItem('guestChatsLastReset')) {
            localStorage.setItem('guestChatsLastReset', new Date().toDateString());
          }
        } else { 
          setRemainingChats(prev => prev - 1);
        }
      }

      if (imgUrls && imageTitles && imageTitles?.length > 0 && imgUrls?.length > 0) {
        for (let i = 0; i < imageTitles?.length; i++) {
          const resourceId = `image-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
          const newResource = {
            id: resourceId,
            type: 'image',
            title: imageTitles[i],
            content: `Image file: ${imageTitles[i]}`,
            timestamp: new Date().toISOString(),
            messageId: aiMessageId,
            url: imgUrls[i]
          };
          handleCreateResource(newResource);
        }
      }
      // Update resources with temporary message ID to use final AI message ID
      if (aiMessageId) {
        setResources(prevResources => {
          if (!prevResources || !chatId) return prevResources;
          const updatedResources = {
            ...prevResources,
            [chatId]: (prevResources[chatId] || []).map(resource =>
              resource?.messageId === tempAiMessageId
                ? { ...resource, messageId: aiMessageId }
                : resource
            )
          };
          return updatedResources;
        });
      }

    } catch (error) {
      if(toSwitch){
        navigate(`/${toSwitch}`, { replace: true });
      }
      // Add this line to ensure loading state is cleared on error
      setIsGeneratingResponse(false);
      console.error('Error in handleSubmit:', error);


      setMessages(prevMessages => 
        prevMessages.filter(msg => 
          msg.id !== tempUserMessageId && msg.id !== tempAiMessageId
        )
      );
      if(editedMessageId){
        setMessages(prevMessages => [...prevMessages, ...cutMessages]);
        setCutMessages([]);
      }else{
        handleSubmissionError(error, currentInput, tempUserMessageId, currentPdfFiles);
      }
    } finally {
      // Make sure auto-scroll stays disabled after completion
      if(toSwitch){
        navigate(`/${toSwitch}`, { replace: true });
      }
      setAutoScroll(false);
      cleanupSubmission();
      
      // Update PDF processing logic
      if (currentPdfFiles && currentPdfFiles?.length > 0) {
        currentPdfFiles.forEach(pdfFile => {
          // Check if pdfFile is the correct object structure
          if (pdfFile && pdfFile.file) {
            const resourceId = `pdf-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            const newResource = {
              id: resourceId,
              type: 'file',
              title: pdfFile.name,
              content: `Reload page for content`, // You might want to update this with actual content
              timestamp: new Date().toISOString(),
              messageId: tempAiMessageId
            };
            
            handleCreateResource(newResource);
          }
        });
      }

      // Focus the textarea after a short delay
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }, 100);
    }

    // After successful message processing, update states
    if (editedMessageId) {
      // Update the edited message content

      // Clear editing state
      setEditingMessageId(null);
      setEditedMessageId(null);
      setEditContent('');
    }

    // Add scroll behavior after message is fully processed
    // setTimeout(() => {
    //   if (messagesEndRef.current) {
    //     messagesEndRef.current.scrollIntoView({ 
    //       behavior: "smooth",
    //       block: "end"
    //     });
    //   }
    // }, 100);
  }, [
    user,
    remainingChats,
    remainingGuestChats,
    isSubmitting,
    editingMessageId,
    pdfFiles,
    isInputProcessing,
    chatIdToUse,
    spaceId,
    isSpaceChat,
    messages,
    currentMode,
    editContent,
    chatId,
    queryClient,
    navigate,
    scrollToBottom,
    processArtifacts,
    generatedContent,
    expandedResource,
    handleCreateResource,
    cleanupSubmission,
    textareaRef,
    handleUpdateTitle,
    handleSubmissionError,
    isLoadingResource,
    showCustomToast,
    editedMessageId,
    cutMessages
  ]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const incomingMessage = params.get('message');
    const incomingMode = params.get('mode') || 'tutor';
    
    if (incomingMessage) {
      // Set the mode first
      selectMode(incomingMode);
      
      // Set the message and submit it
      setInput(decodeURIComponent(incomingMessage));
      inputRef.current = decodeURIComponent(incomingMessage);
      
      // Use setTimeout to ensure state updates have propagated
      setTimeout(() => {
        handleSubmit({ preventDefault: () => {} });
      }, 100);
      
      // Clean up the URL
      const newUrl = window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [location, handleSubmit]); // Add any other dependencies as needed

    // Submit edited message
  const handleEditSubmit = useCallback((messageId) => {
    setEditedMessageId(messageId);
    setEditingMessageId(null);
    setInput(editContent);
    inputRef.current = editContent;
    handleSubmit({ preventDefault: () => {} });
  }, [editContent, handleSubmit]);

  // Handle stopping the generation of AI messages
  // const handleStopGenerating = () => {
  //   if (abortController) {
  //     abortController.abort();
  //     setIsGenerating(false);

  //     setIsSubmitting(false);
      

  //   } else {
  //     console.warn('No active AbortController to abort.');
  //   }
  // };

  // Restore input in the textarea


  // Cleanup after submission
  // Abort controller cleanup on unmount
    useEffect(() => {
      return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // Handle pending navigation
  useEffect(() => {
    if (pendingNavigation && !abortControllerRef.current?.signal.aborted) {
      navigate(`/${pendingNavigation}`, { replace: true });
      setPendingNavigation(null);
    }
  }, [pendingNavigation, navigate]);

  // Update the toggleSidebar function
  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => {
      const newState = !prev;
      localStorage.setItem('isSidebarOpen', JSON.stringify(newState));
      return newState;
    });
    
    if (window.innerWidth <= 768) {
      setIsHoverOpen(false);
    }
  }, []);

  // Copy message content to clipboard
  const copyToClipboard = useCallback((content, messageId) => {
    copyRichText(content)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }, []);

  // Update handleFileChange function to properly count existing files
  const handleFileChange = (e) => {
    if (!user) {
      showCustomToast('error', 'Please sign in to upload files.');
      return;
    }

    const files = Array.from(e.target.files);
    const currentPendingFiles = pdfFiles?.length || 0;
    const remainingMessageSlots = MAX_FILES_PER_MESSAGE - currentPendingFiles;

    if (remainingMessageSlots <= 0) {
      showCustomToast('error', `You can only attach up to ${MAX_FILES_PER_MESSAGE} files per message.`);
      return;
    }

    // Count existing files in messages
    const existingFiles = messages.reduce((count, message) => {
      let fileCount = 0;
      
      // Count PDFs
      if (message.pdf_title) {
        try {
          const pdfTitles = JSON.parse(message.pdf_title);
          fileCount += Array.isArray(pdfTitles) ? pdfTitles?.length : 0;
        } catch (e) {}
      }
      
      // Count Images
      if (message.imagestitle) {
        try {
          const imageTitles = JSON.parse(message.imagestitle);
          fileCount += Array.isArray(imageTitles) ? imageTitles?.length : 0;
        } catch (e) {}
      }
      
      return count + fileCount;
    }, 0);

    // Get user's tier and max files allowed
    const userTier = user?.subscription?.tier?.toLowerCase() || 'free';
    const maxFilesForTier = MAX_FILES_PER_CHAT[userTier];
    const remainingTierSlots = maxFilesForTier - existingFiles;

    if (remainingTierSlots <= 0) {
      if (userTier === 'free' || userTier === 'premium' || userTier === 'pro') {
        setSalesModalFeature('Additional File Upload');
        setShowSalesModal(true);
      } else {
        showCustomToast('error', `You've reached the maximum of ${maxFilesForTier} files for your tier.`);
      }
      return;
    }

    // Process only the number of files that fit within both limits
    const filesToProcess = files.slice(0, Math.min(remainingMessageSlots, remainingTierSlots));

    filesToProcess.forEach(file => {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > MAX_FILE_SIZE_MB) {
        showCustomToast('error', `File ${file.name} exceeds ${MAX_FILE_SIZE_MB}MB limit`);
        return;
      }

      if (ACCEPTED_FILE_TYPES[file.type]) {
        const newFile = {
          id: `${file.name}-${file.size}-${Date.now()}`,
          name: file.name,
          file: file,
          type: ACCEPTED_FILE_TYPES[file.type]
        };

        setPdfFiles(prev => [...prev, newFile]);
        showCustomToast('success', `${file.name} attached successfully.`);
      } else {
        showCustomToast('error', `${file.name} is not a supported file type.`);
      }
    });

    e.target.value = '';
  };

  // Update removePDF function
  const removePDF = (idToRemove) => {
    setPdfFiles(prev => prev.filter(pdf => pdf.id !== idToRemove));
  };
  // Update handleAttachClick to use the same file counting logic
  const handleAttachClick = (e) => {
    e.preventDefault();
    
    if (!user) {
      showCustomToast('error', 'Please sign in to upload files.');
      return;
    }

    // Check per-message limit first
    if ((pdfFiles?.length || 0) >= MAX_FILES_PER_MESSAGE) {
      showCustomToast('error', `You can only attach up to ${MAX_FILES_PER_MESSAGE} files per message.`);
      return;
    }

    // Count existing files in messages
    const existingFiles = messages.reduce((count, message) => {
      let fileCount = 0;
      
      // Count PDFs
      if (message.pdf_title) {
        try {
          const pdfTitles = JSON.parse(message.pdf_title);
          fileCount += Array.isArray(pdfTitles) ? pdfTitles?.length : 0;
        } catch (e) {}
      }
      
      // Count Images
      if (message.imagestitle) {
        try {
          const imageTitles = JSON.parse(message.imagestitle);
          fileCount += Array.isArray(imageTitles) ? imageTitles?.length : 0;
        } catch (e) {}
      }
      
      return count + fileCount;
    }, 0);

    const userTier = user?.subscription?.tier?.toLowerCase() || 'free';
    const maxFilesForTier = MAX_FILES_PER_CHAT[userTier];

    if (existingFiles >= maxFilesForTier) {
      if (userTier === 'free' || userTier === 'premium' || userTier === 'pro') {
        setSalesModalFeature('Additional File Upload');
        setShowSalesModal(true);
      } else {
        showCustomToast('error', `You've reached the maximum of ${maxFilesForTier} files for your tier.`);
      }
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  const handleReportMessage = useCallback(async (message, isLastAIMessage) => {
    if(user){
      if(isLastAIMessage){
        await reportMessage(message);
        if(remainingChats > 0 || remainingChats === null){
          handleRegenerateMessage(message.id);
        }
      } else {
        await reportMessage(message);
        if(remainingChats > 0 || remainingChats === null){
          handleEditStart(message);
        }
      }
    } else {
      await reportMessage(message);
    }
    showCustomToast('success', 'Message reported successfully');
  }, [user, remainingChats, handleRegenerateMessage, handleEditStart, showCustomToast]);

  const handleCharacterLimit = useCallback((currentLength) => {
    if (currentLength > MAX_MESSAGE_LENGTH) {
      showCustomToast('error', `You've reached the character limit!`);
      return true;
    }
    return false;
  }, [showCustomToast]);

  // Add this new useEffect
  useEffect(() => {
    if (chatId && chatId !== 'new' && filteredMessages?.length > 0) {
      setTimeout(() => {
        scrollToBottom();
      }, 100); // Small delay to ensure content is loaded
    }
  }, [chatId, filteredMessages?.length, scrollToBottom]);

  // Update the renderMessage function
  const renderMessage = useCallback((message, index, array) => {
    const isEditing = message.id === editingMessageId;
    const activeIndex = 0;
    const activeMessageGroup = groupedMessages.find(group => group[0].id === message.id);
    const activeMessage = activeMessageGroup ? activeMessageGroup[activeIndex] : message;

    const isLastMessage = index === array?.length - 1;
    const isLastAIMessage = message.is_ai && array.slice(index + 1).every(msg => !msg.is_ai);
    const imageTitles = message.imagestitle ? JSON.parse(message.imagestitle) : [];
    const pdfTitles = message.pdf_title ? JSON.parse(message.pdf_title) : [];

    // Add this condition at the start of the regenerating message check
    if (message.id === regeneratingMessageId) {
      return (
        <div key={message.id} className={`message ai ${isSidebarOpen || isHoverOpen ? 'sidebar-open' : ''}`}>
          <img src={theme === 'dark' ? CognoraLogoTransparentDark : studyBuddyLogo} alt="StudyBuddy AI" className="ai-icon" style={{backgroundColor: 'transparent'}} />
          <div className="message-content">
            {isPDFProcessing && (
              <div className="pdf-processing-status">
                <div className="processing-icon">
                  <FileText className="animate-pulse" size={20} />
                </div>
                <div className="processing-text">
                  <span className="processing-title">Processing File</span>
                  <span className="processing-subtitle">Analyzing document contents...</span>
                </div>
              </div>
            )}
            
            <AIResponseRenderer 
              content={message.content} // Show the current accumulated content
              renderResource={renderResource} 
              messageId={message.id}
            />
          </div>
          {renderLoadingSymbol()} {/* Updated condition */}
        </div>
      );
    }

    /**
     * Renders content with support for multiple <quote></quote> tags.
     * Splits the content based on the <quote> tags and renders each part accordingly.
     * 
     * @param {string} content - The message content to render.
     * @returns {JSX.Element} The rendered content with styled quotes.
     */
    // const renderContent = (content) => {
    //   const quoteRegex = /<quote>(.*?)<\/quote>/gs;
    //   // Updated regex to better handle code blocks
    //   const codeBlockRegex = /```([\w-]*)\n?([\s\S]*?)```/g;
    //   const parts = [];
    //   let lastIndex = 0;
    //   let match;

    //   // Function to process text and add it to parts
    //   const addTextToParts = (text, key) => {
    //     if (text.trim()) {
    //       parts.push(
    //         <MarkdownRenderer 
    //           key={key} 
    //           content={text} 
    //           disallowedElements={disallowedElements}
    //         />
    //       );
    //     }
    //   };

    //   // Iterate over all matches (quotes and code blocks)
    //   while ((match = quoteRegex.exec(content)) !== null || (match = codeBlockRegex.exec(content)) !== null) {
    //     const start = match.index;
    //     const fullMatch = match[0];

    //     // Add the text before the current match
    //     if (start > lastIndex) {
    //       const text = content.substring(lastIndex, start);
    //       addTextToParts(text, lastIndex);
    //     }

    //     if (fullMatch.startsWith('<quote>')) {
    //       // Handle quote content
    //       const quoteContent = match[1]?.trim();
    //       if (quoteContent) {
    //         parts.push(
    //           <div key={start} className="quote-content">
    //             {quoteContent}
    //           </div>
    //         );
    //       }
    //     } else {
    //       // Handle code block with improved parsing
    //       const language = match[1]?.trim() || 'text';
    //       const codeContent = match[2]?.trim();
          
    //       if (codeContent) {
    //         parts.push(
    //           <div key={start} className="modern-code-display">
    //             <SyntaxHighlighter
    //               language={language}
    //               wrapLines={true}
    //               wrapLongLines={true}
    //               customStyle={{
    //                 margin: 0,
    //                 borderRadius: '8px',
    //                 padding: '1rem',
    //               }}
    //             >
    //               {codeContent}
    //             </SyntaxHighlighter>
    //           </div>
    //         );
    //       }
    //     }

    //     lastIndex = start + fullMatch?.length;
    //   }

    //   // Add any remaining text after the last match
    //   if (lastIndex < content?.length) {
    //     const text = content.substring(lastIndex);
    //     addTextToParts(text, lastIndex);
    //   }

    //   return <>{parts}</>;
    // };

    return (
      <div 
        key={message.id || generateTempId()}
        className={`message ${message.is_ai ? 'ai' : 'user'} ${isSidebarOpen || isHoverOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-visible' : ''}`}
        ref={isLastMessage ? lastMessageRef : null}
      >
        {message.is_ai ? (
          <img src={theme === 'dark' ? CognoraLogoTransparentDark : CognoraLogo} alt="StudyBuddy AI" className="ai-icon" />
        ) : null}
        <div className={`message-content ${isEditing ? 'editing' : ''}`}>

          {isEditing ? (
            <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(message.id); }}>
              <textarea
                ref={editInputRef}
                className="edit-input auto-expand"
                value={editContent.split('<|endofinput|>')[0]} 
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (!handleCharacterLimit(newValue?.length)) {
                    setEditContent(newValue);
                    // Auto-adjust height
                    e.target.style.height = '0px'; // Reset height
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }
                }}
                onInput={handleTextareaInput}
                autoFocus={true}
                spellCheck="true" // Enable spell checking
              />
            </form>
          ) : (
            message.is_ai ? (
              <AIResponseRenderer
                content={activeMessage.content}           
                renderResource={renderResource}
                messageId={message.id}
              />
            ) : (
              <div className="user-message-content">
                {(activeMessage.content.split('<|endofinput|>')[0].trim())}
                <div className="user-icon" aria-label="User icon" title="User icon">
                    {user?.name?.charAt(0).toUpperCase()}
                </div>
              </div>
            )
          )}
          {message.attachment && (
            <div className="attached-pdf">
              <div className="pdf-card">
                <Paperclip size={16} className="pdf-icon" />
                <span className="pdf-name">{message.attachment.name}</span>
              </div>
            </div>
          )}
          {message.is_ai ? (
            <div className="message-footer">

              <div className={`message-actions ${isLastAIMessage ? 'last-message' : 'not-last-message'}`}>
          <button 
                  className="action-button" 
                  aria-label="Copy message"
                  title="Copy message"
                  onClick={() => copyToClipboard(activeMessage.content, message.id)}
                >
                  {copiedMessageId === message.id ? <Check size={16} /> : <Copy01Icon size={16} />}
                  <span className="hover-text bottom">Copy</span>
          </button>
                {isLastAIMessage && user && !isSpaceChat && (
                  <>
                    <button 
                      className="action-button regenerate-button" 
                      aria-label="Regenerate message"
                      title="Regenerate message"
                      onClick={() => handleRegenerateMessage(message.id)}
                      disabled={regeneratingMessageId === message.id}
                    >
                      <RefreshCw size={16} />
                      <span className="hover-text bottom">Regenerate Message</span>               
                    </button>
                  </>
                )}
                <button className="action-button thumbs-down-button" onClick={() => handleReportMessage(message, isLastAIMessage)}>
                    <ThumbsDownIcon size={16} />
                    <span className="hover-text right">Report Response</span>
                </button>
                {/* {message.used_web ? (
                  <div className="web-indicator" title="This message used the web">
                    <Globe size={16} />
                    <span>Web</span>
                  </div>
                ) : null} */}
        </div>
      </div>
          ) : (
            
            <div className={`message-footer ${isEditing ? 'editing' : ''}`}>
              {isEditing ? (
                <>
                  <div className="edit-actions">
                    <button type="button" onClick={handleEditCancel} className="cancel-button">Cancel</button>
                    <button type="submit" onClick={handleEditSubmit} className="save-button">Save</button>
                  </div>
                  <div className="edit-disclaimer">
                    Note: Editing will remove subsequent messages in this conversation, this action cannot be undone.
                  </div>
                </>
              ) : (
                <>
                {(imageTitles?.length === 0 && pdfTitles?.length === 0) && (
                    <button 
                      className="action-button edit-button" 
                      title="Edit message" 
                      onClick={() => handleEditStart(message)}
                    >
                      <PencilEdit02Icon size={18} />
                    </button>
                  )}
                  {(imageTitles?.length > 0 || pdfTitles?.length > 0) && (
                    <div className="message-actions">
                      {message.imagestitle != null && (() => {
                        try {
                          const imageTitles = JSON.parse(message.imagestitle);
                          if (Array.isArray(imageTitles) && imageTitles?.length > 0) {
                            return (
                              <button className="pdf-tag" onClick={() => setIsResourcesVisible(true)}>
                                <Image size={16} />
                                <span>{`${imageTitles?.length}`}</span>
                                {/* <span className='hover-text bottom'>${imageTitles}</span> */}
                              </button>
                            );
                          }
                        } catch (e) {
                          return null;
                        }
                      })()}
                      {message.pdf_title && (() => {
                        try {
                          const pdfTitles = JSON.parse(message.pdf_title);
                          if (Array.isArray(pdfTitles) && pdfTitles?.length > 0) {
                            return (
                              <button className="pdf-tag" onClick={() => setIsResourcesVisible(true)}>
                                <FileAttachmentIcon size={16} />
                                <span>{`${pdfTitles?.length}`}</span>
                                {/* <span className='hover-text bottom'>${pdfTitles}</span> */}
                              </button>
                            );
                          }
                        } catch (e) {
                          return null;
                        }
                      })()}
                      {/* <button 
                        className="action-button"
                        aria-label="Copy message"
                        title="Copy message"
                        onClick={() => copyToClipboard(activeMessage.content, message.id)}
                      >
                        {copiedMessageId === message.id ? <Check size={16} /> : <Copy01Icon size={16} />}
                        <span className="hover-text bottom">Copy</span>
                      </button>
                      {user && (
                        <div className="user-icon" aria-label="User icon" title="User icon">
                          {user.name.charAt(0).toUpperCase()}
                        </div>
                      )} */}
                    </div>
                  )}

                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }, [
    editingMessageId,
    editContent,
    handleEditSubmit,
    groupedMessages,
    copyToClipboard,
    copiedMessageId,
    handleRegenerateMessage,
    regeneratingMessageId,
    isPDFProcessing,
    renderResource,
    isSidebarOpen,
    isHoverOpen,
    handleCharacterLimit,
    isResourcesVisible,
    setIsResourcesVisible,
    lastMessageRef,
    editInputRef,
    handleEditCancel,
    handleEditStart,
    handleReportMessage,
    user,
    isSpaceChat,
    theme
  ]);

  // Move getRandomHeader outside the component to prevent recreating it on every render
  const getRandomHeader = (userName) => {
    const greetingWords = ['Hello', 'Hi', 'Hey', 'Welcome', 'Good day'];
    const formattedName = userName && userName?.length > 2 ? ` ${userName.split(' ')[0].charAt(0).toUpperCase() + userName.split(' ')[0].slice(1).toLowerCase()}` : '';
    const messages = [
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! I'm your AI tutor.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! How can I help you learn?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What shall we explore today?`, 
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to learn?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What questions do you have?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! How can I help?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What topic interests you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's tackle any subject.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! I'm here to help.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's learn together.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to begin?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What's on your mind?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! How can I assist you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What interests you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's get started.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What can we solve?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's explore together.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to discover?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What's your goal?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What sparks your interest?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! How can I guide you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's overcome challenges.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Where shall we start?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What's on your mind?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's begin learning.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to study?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What's your focus?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's learn something.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to excel?`,
    ];

    return messages[Math.floor(Math.random() * messages?.length)];
  };

  // Render default display when there are no messages
  const renderDefaultDisplay = () => (
    <div className={`default-display ${pdfFiles?.length > 0 ? 'pdf-attached' : ''}`}>
      <img 
        src={theme === 'light' ? CognoraLogo : CognoraLogoTransparentDark} 
        alt="Cognora Logo" 
        className="imglogo" 
      />
      {windowWidth > 600 ? (
        <h1 className="dynamic-header">
          {dynamicHeader}
        </h1>
      ) : (
        <h1 className="dynamic-header">
          Hello!
        </h1>
      )}
      
      {!user && <div className="suggestions-section">
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li 
              key={index} 
              onClick={() => (remainingChats > 0 || remainingChats == null) ? submitSuggestion(suggestion.text) : null}
              className={`suggestion-item ${(remainingChats <= 0 && remainingChats != null) ? 'disabled' : ''}`}
            >
              {suggestion.icon}
              <span>{suggestion.text}</span>
            </li>
          ))}
        </ul>
      </div>}

      {pdfFiles?.length > 0  ? (
          <div className="pdf-preview">
            {pdfFiles.map(pdf => (
              <div key={pdf.id} className="pdf-preview-content">
                <FaFilePdf className="pdf-icon" />
                <span className="pdf-name">{pdf.name}</span>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    removePDF(pdf.id);
                  }} 
                  className="remove-pdf"
                  aria-label="Remove PDF"
                >
                  <FaTimes />
                </button>
    </div>
            ))}
          </div>
        ) : null}

      {remainingChats!= null && remainingChats < 1 && filteredMessages?.length === 0 && renderChatLimitBanner()}
  
      
      <div className={`input-area empty ${user ? 'user' : ''}`}>
      <form className={`input-wrapper empty ${pdfFiles?.length > 0 ? 'pdf-attached' : ''}`} onSubmit={handleSubmit}>
        {/* Remove PDF button for non-users */}
        <textarea
          ref={(el) => {
            textareaRef.current = el;
            if (el) {
              el.value = input;
              inputRef.current = input;
              adjustTextareaHeight();
            }
          }}
          value={input}
          onChange={(e) => {
            const newValue = e.target.value;
            if (!handleCharacterLimit(newValue?.length)) {
              setInput(newValue);
              inputRef.current = newValue;
              adjustTextareaHeight();
            }
          }}
          onKeyDown={handleKeyDown}
          onInput={adjustTextareaHeight}
          placeholder="Type your message here..."
          aria-label="Chat input" 
          className="chat-input scrollable"
          disabled={isSubmitting}
          autoFocus={true}
        />

        <div className="input-button-wrapper">
          <div className="input-button-wrapper-left">
            <button
                type="button"
                className="attach-button empty"
                onClick={handleAttachClick}
                aria-label="Attach files"
                title="Attach files"
                disabled={!user}
              >
                <Attachment02Icon size={21}  />
                <span className="hover-text bottom">
                  {user ? 'Upload up to 3 files (PDFs, Images, Documents)' : 'Sign in to Upload Files'}
                </span>
              </button>
          
            <input
              type="file"
              accept={Object.keys(ACCEPTED_FILE_TYPES).join(',')}å
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
              id="file-upload"
            />

          <div className="mode-selecto" ref={dropdownRef}>
          <button 
            type="button"
            className="mode-selector-button" 
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsDropdownOpen(!isDropdownOpen);
            }}
          >
            {currentMode.charAt(0).toUpperCase() + currentMode.slice(1)}
            <ChevronDown size={16} />
          </button>
          {isDropdownOpen && (
            <div className={`mode-dropdown ${isDropdownOpen ? 'active' : ''}`}>
              <div 
                className="mode-option mode-qa" 
                onClick={(e) => {
                  e.stopPropagation();
                  selectMode('qa');
                }}
                aria-label="Switch to Q&A mode"
                title="Switch to Q&A mode"
              >
                Q&A
            </div>
              <div 
                className="mode-option mode-tutor" 
                onClick={(e) => {
                  e.stopPropagation();
                  selectMode('tutor');
                }}
                title="Switch to Tutor mode"
              >
                Tutor
            </div>
              <div 
                className="mode-option mode-brainstorm" 
                onClick={(e) => {
                  e.stopPropagation();
                  selectMode('brainstorm');
                }}

                title="Switch to Brainstorm mode"
              >
                Brainstorm
          </div>
              <div 
                className="mode-option mode-debate" 
                onClick={(e) => {
                  e.stopPropagation();
                  selectMode('debate');
                }}
                title="Switch to Debate mode"
              >
                Debate
              </div>
            </div>
          )}
        </div>
          </div>

          <div className="input-button-wrapper-right">
            <button 
              type="submit" 
              disabled={
                isSubmitting || 
                isInputProcessing || 
                (!input.trim()) || 
                (remainingChats <= 0 && remainingChats != null)
              }      
            >
              <UploadCircle01Icon size={34} style={{paddingRight: '2px', color: 'var(--text-primary)', backgroundColor: 'var(--background-light)'}} />
              {isSubmitting || isInputProcessing || (!input.trim()) || (remainingChats <= 0 && remainingChats != null) ? (
                <span className="hover-text">
                  {isSubmitting ? "Sending message..." :
                  isInputProcessing ? "Processing..." :
                  !input.trim() ? "Please enter a message" :
                  remainingChats <= 0 && remainingChats != null ? "No chats remaining" : "Send A Message"}
                </span>
              ) : null}
            </button>
          </div>
        </div>
      </form>      
    </div>
          
      {user && (
        <>
        {chatsData?.chats && chatsData?.chats?.length > 0 && (
          <RecentChats chats={chatsData.chats} />
        )}
        </>
      )}
      </div>
    );

  // Submit a suggestion as a message
  const submitSuggestion = (suggestion) => {
    if (remainingChats <= 0 && remainingChats != null) {
      return;
    }
    setInput(suggestion);
    inputRef.current = suggestion;
  };

  // Render chat limit banner
  const renderChatLimitBanner = () => {
    const currentRemaining = user ? remainingChats : remainingGuestChats;  
    return currentRemaining <= 0 && (
      <div className="chat-limit-banner">
        <div className="chat-limit-content">
          <span>
            {currentRemaining === 0 
              ? window.innerWidth <= 768 ? "Daily limit reached" : "You've reached your daily messages limit."
              : window.innerWidth <= 768 ? `${currentRemaining} left` : `${currentRemaining} chat${currentRemaining === 1 ? '' : 's'} remaining. `}
            <Link to="/pricing" className="signup-link">
              Upgrade
            </Link>
          </span>
        </div>
      </div>
    );
  };

// Add debounce to prevent too frequent saves
const SAVE_DELAY = 500; // ms

// Load drafts
useEffect(() => {
  try {
    // Single source of truth for draft loading
    const drafts = {
      current: localStorage.getItem('draft_current'),
      new: localStorage.getItem('draft_new'),
      chatSpecific: chatId ? localStorage.getItem(`draft_${chatId}`) : null
    };

    const draftToUse = chatId === 'new' 
      ? (drafts.current || drafts.new)
      : (drafts.chatSpecific || drafts.new);

    if (draftToUse) {
      try {
        // Parse the JSON and extract just the content
        const parsedDraft = JSON.parse(draftToUse);
        const draftContent = parsedDraft.content || '';
        
        setInput(draftContent);
        if (inputRef.current) inputRef.current = draftContent;
        if (textareaRef.current) textareaRef.current.value = draftContent;
      } catch (e) {
        // Fallback for old draft format
        setInput(draftToUse);
        if (inputRef.current) inputRef.current = draftToUse;
        if (textareaRef.current) textareaRef.current.value = draftToUse;
      }
    }
  } catch (error) {
    console.error('Error loading draft:', error);
  }
}, [chatId]);

// Add these constants at the top of your file
const MAX_DRAFTS = 50; // Maximum number of drafts to keep
const DRAFT_PREFIX = 'draft_';

// Add this function to manage draft limits
const enforceDraftLimit = () => {
  try {
    // Get all draft keys
    const draftKeys = Object.keys(localStorage)
      .filter(key => key.startsWith(DRAFT_PREFIX))
      .sort((a, b) => {
        // Sort by last modified timestamp
        const timeA = localStorage.getItem(a)?.timestamp || 0;
        const timeB = localStorage.getItem(b)?.timestamp || 0;
        return timeB - timeA;
      });

    // Remove oldest drafts if we exceed the limit
    if (draftKeys?.length > MAX_DRAFTS) {
      draftKeys
        .slice(MAX_DRAFTS)
        .forEach(key => localStorage.removeItem(key));
    }
  } catch (error) {
    console.error('Error enforcing draft limit:', error);
  }
};

// Update your draft saving effect to include the limit check
useEffect(() => {
  const saveDraft = debounce(() => {
    try {
      if (input.trim() && !isSubmitting) {
        // Save the draft with timestamp
        const draftData = {
          content: input,
          timestamp: Date.now()
        };

        if (!chatId || chatId === 'new') {
          localStorage.setItem('draft_new', JSON.stringify(draftData));
        } else {
          localStorage.setItem(`draft_${chatId}`, JSON.stringify(draftData));
          localStorage.setItem('draft_current', JSON.stringify(draftData));
        }

        // Check and enforce draft limit
        enforceDraftLimit();
      }
    } catch (error) {
      console.error('Error saving draft:', error);
    }
  }, SAVE_DELAY);

  saveDraft();
  return () => saveDraft.cancel();
}, [input, isSubmitting, chatId]);

// Add cleanup for old drafts
useEffect(() => {
  const DRAFT_MAX_AGE = 7 * 24 * 60 * 60 * 1000; // 7 days

  const cleanupOldDrafts = () => {
    try {
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('draft_')) {
          const draftData = localStorage.getItem(key);
          if (draftData) {
            const timestamp = JSON.parse(draftData).timestamp;
            if (Date.now() - timestamp > DRAFT_MAX_AGE) {
              localStorage.removeItem(key);
            }
          }
        }
      });
    } catch (error) {
      console.error('Error cleaning up drafts:', error);
    }
  };

  cleanupOldDrafts();
}, []); // Run once on component mount

  // Update the renderLoadingSymbol function
  const renderLoadingSymbol = () => (
    <div className="ai-typing-indicator">
      <div className="typing-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );

  // Initial textarea height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '23px';
    }
  }, []);

  // Adjust textarea height dynamically
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '23px';
      const newHeight = Math.min(textareaRef.current.scrollHeight, 250);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  // Handle scroll to manage the visibility of the scroll button
  const handleScroll = useCallback(() => {
    if (!messagesContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
    const threshold = 100;
    const atBottom = scrollHeight - scrollTop - clientHeight <= threshold;
    
    setShowScrollButton(!atBottom);
  
    if (isGeneratingResponse && atBottom) {
      setShowScrollButton(false);
    }
  }, [isGeneratingResponse]); // Remove isGeneratingResponse dependency since it's only used for UI

  // Attach scroll event listener
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Handle key down events for the textarea
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      // Check the same conditions as the submit button
      const isDisabled = 
        isSubmitting || 
        isInputProcessing || 
        (!input.trim()) || 
        (remainingChats <= 0 && remainingChats != null);

      if (!isDisabled) {
        handleSubmit(e);
      }
    }
  };

  // Handle search query changes
  useEffect(() => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      setFilteredMessages(prevMessages => 
        messages.filter(message =>
          message?.content?.toLowerCase().includes(lowercasedQuery)
        )
      );
    } else {
      setFilteredMessages(messages);
    }
  }, [searchQuery, messages]); // Keep both dependencies

  // Add this useEffect to update currentMode when localStorage changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'defaultChatMode') {
        setCurrentMode(e.newValue || 'qa');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // No dependencies needed

  // Add this useEffect to handle clicks outside the mode selector
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectMode = (mode) => {
    setCurrentMode(mode);
    localStorage.setItem('defaultChatMode', mode); // Add this line to persist the selection
    setIsDropdownOpen(false);
  };

  // Add this function to toggle the Resources section
  const toggleResources = () => {
    setIsResourcesVisible(prev => !prev);
    if (isMobile) {
        setIsSidebarOpen(false);
      }
    };

  // Add this new function to handle closing the Resources section
  const closeResources = () => {
    setIsResourcesVisible(false);
  };

  const getResourceIcon = (type) => {
    switch (type) {
      case 'essay':
        return <FileText size={16} />;
      case 'pdf':
        return <FileText size={16} />;
      case 'visualization':
        return <BarChartOutlined size={16} />;
      default:
        return <File size={16} />;
    }
  };

  const renderExpanded = (onClose, versions) => {
    return (
    <ExpandedResource
      resource={expandedResource}
      onClose={() => {
        setIsResourcesExpanded(false);
        setExpandedResource(null);
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('resource');
        newParams.delete('version');
        setSearchParams(newParams);
      }}
      onImprove={handleImprove}
      onExplain={handleExplain}
      allVersions={resources[chatId]?.filter(r => r.title === expandedResource.title)}
      desiredVersion={expandedVersion}
      showCustomToast={showCustomToast}
      isSpaceChat={isSpaceChat}  // Add this prop
      onAddToSpace={handleAddArtifactToSpace}  // Add this prop
    />
    )
  }

  // Add this new useEffect
  useEffect(() => {
    if (isResourcesExpanded) {
      setIsSidebarOpen(false);
    }
  }, [isResourcesExpanded]);

  // Check for state from auth flow
  useEffect(() => {
    if (location.state) { 
      const { inputValue, attachments } = location.state;
      if (inputValue) {
        setInput(inputValue);
        // Clear the navigation state after using it
        window.history.replaceState({}, document.title, window.location.pathname);
      }
      if (attachments) setPdfFiles(attachments[0]);
    }

    // Check for pending state from checkout
    const pendingState = localStorage.getItem('pendingChatState');
    if (pendingState) {
      const { inputValue, attachments } = JSON.parse(pendingState);
      if (inputValue) setInput(inputValue);
      if (attachments) setPdfFiles(attachments[0]);
      // Remove from localStorage after using it
      localStorage.removeItem('pendingChatState');
    }
  }, [location]);

  // Add this effect near your other useEffects
  useEffect(() => {
    if (location.state?.autoSubmitMessage) {
      // Set the input and mode
      setInput(location.state.autoSubmitMessage);
      if (location.state.mode) {
        selectMode(location.state.mode);
      }
      
      // Auto submit after a short delay
      const timer = setTimeout(() => {
        handleSubmit(new Event('submit'));
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [location.state, handleSubmit]);

  // Add this new component for the chat not found message
  const ChatNotFound = () => {
    
    const message = messagesError?.isAuthError
      ? "Please log in to view this chat."
      : "This chat doesn't exist.";

    return (
      <div className="chat-not-found">

        <div className="chat-not-found-content">
        <img src={theme === 'light' ? CognoraLogo : CognoraLogoTransparentDark} alt="Cognora Logo" className="notexistslogo" />
          <h2>Chat Not Found</h2>
          <p>{message}</p>
          {messagesError?.isAuthError && (
            <Link to="/login" className="login-button">
              Log In
            </Link>
          )}
        </div>
      </div>
    );
  };



  const MemoizedCustomToast = memo(({ message, type, onClose }) => {
    return (
      <div className={`custom-toast ${type}`}>
        <span>{message}</span>
      </div>
    );
  }, (prevProps, nextProps) => {
    return (
      prevProps.message === nextProps.message &&
      prevProps.type === nextProps.type
    );
  });

  // Update the useEffect that handles resources visibility
  useEffect(() => {
    const hasPDFsOrResources = resources?.[chatId]?.length > 0;
    
    setIsResourcesVisible(() => 
      chatId !== 'new' && 
      chatId !== undefined && 
      user?.id && // Only depend on user ID
      hasPDFsOrResources && 
      !isMobile
    );
  }, [chatId, resources, user?.id, isMobile]); // Simplified dependencies

  // Add this effect to handle chat switching
  useEffect(() => {
    if (chatId && expandedResource) {
      // Check if the current expanded resource belongs to the current chat
      const chatResources = resources[chatId] || [];
      const resourceExists = chatResources.some(
        resource => resource.title === expandedResource.title
      );

      if (!resourceExists) {
        // Reset expanded resource and related states
        setExpandedResource(null);
        setIsResourcesExpanded(false);
        
        // Remove resource-related query params
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('resource');
        newParams.delete('version');
        setSearchParams(newParams);
      }
    }
  }, [chatId, expandedResource, resources, setSearchParams, searchParams]);



  // Update the useEffect cleanup for message streaming
  useEffect(() => {
    return () => {
      // Instead of aborting the request, just clean up the frontend state
      setIsGeneratingResponse(false);
      setIsSubmitting(false);
      setGeneratedContent('');
      
      // Don't abort the controller, let backend continue
      abortControllerRef.current = null;
    };
  }, []);

  // Add this new useEffect to focus the textarea on page load
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []); // Empty dependency array means this runs once on mount

  // Add new state for mobile search overlay
  const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(false);

  // Update the header section to show different search UI based on screen size
  const renderHeaderCenter = () => {
    if (!user) return null;

    return (
      <div className="search-container">
        {window.innerWidth >768 ? (
          <input 
            type="text" 
            placeholder="Search conversations..." 
            className="search-input"
            value={searchQuery}
            onChange={handleSearch}
            aria-label="Search conversations"
            inputMode="search"
            autoComplete="off"
          />
        ) : (
          <button
            className="mobile-search-button"
            onClick={() => setIsSearchOverlayOpen(true)}
            aria-label="Open search"
          >
            <Search size={20} />
          </button>
        )}
      </div>
    );
  };

    // Add memoization for the sidebar component
    const MemoizedSidebar = useMemo(() => (
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        updateTitle={handleUpdateTitle}
        showCustomToast={showCustomToast}
        showSalesModal={showSalesModal}
        setSalesText={setSalesModalFeature}
        handleAddChat={handleAddChat}
        onMouseEnter={handleHoverOpen}
        onMouseLeave={handleHoverClose}
        isHoverOpen={isHoverOpen}
      />
    ), [
      isSidebarOpen,
      toggleSidebar,
      handleUpdateTitle,
      showCustomToast,
      showSalesModal,
      setSalesModalFeature,
      handleAddChat,
      handleHoverOpen,
      handleHoverClose,
      isHoverOpen
    ]);

  // Add this new useEffect near your other useEffect hooks
  useEffect(() => {
    // Only proceed if we have a valid chatId
    if (chatId && chatId !== 'new' && chatId !== 'undefined') {
      // Update URL based on whether it's a space chat or not
      if (spaceId && isSpaceChat) {
        window.history.replaceState({}, '', `/ss/${chatId}`);
      } else if (!isSpaceChat) {
        window.history.replaceState({}, '', `/${chatId}`);
      }
    }
  }, [chatId, isSpaceChat, spaceId]); // Dependencies that trigger the effect

  // Add this new component for the space selection dropdown
  const AddToSpaceButton = ({ chatId, onAdd }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    
    // Fetch available spaces
    const { data: spaces = [] } = useQuery(
      'spaces',
      () => queryClient.getQueryData('spaces') || [],
      {
        enabled: false // Don't fetch, just access cache
      }
    );

    // Handle clicking outside to close dropdown
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleAddToSpace = async (spaceId) => {
      try {
        await addChatToSpace(spaceId, chatId);
        onAdd(spaceId);
        setIsOpen(false);
        setIsSidebarOpen(false);
        queryClient.invalidateQueries('space-chats');
        showCustomToast('success', 'Chat added to space successfully');
      } catch (error) {
        console.error('Error adding chat to space:', error);
        showCustomToast('error', 'Failed to add chat to space');
      }
    };

    return (
      <div className="add-to-space-container" ref={dropdownRef}>
        <button
          className=""
          onClick={() => {
            if (!isSpaceChat && filteredMessages?.length === 0) {
              if (user?.tier !== 'free' && user?.tier !== 'premium') {
                navigate('/study-spaces');
              } else{
                navigate('/pricing#study-spaces');
              }
            } else {
              setIsOpen(!isOpen);
            }
          }}
        >
          {!isSpaceChat && filteredMessages?.length === 0 ? (
            <>
              <div className="rounded-icon">
                <DashboardCircleIcon size={13} />
              </div>
              <span className="hover-text bottom">Go to Study Spaces</span>
            </>
          ) : (
            <>
              <PlusSignCircleIcon size={24} />
              <span className="hover-text bottom">Add Chat to Study Space</span>
            </>
          )}
        </button>
        {isOpen && spaces?.length > 0 && (
        <div className="space-dropdown">
          <div className="space-dropdown-header">
            <span>Add to Space</span>
            <Link to="/study-spaces" className="spaces-link">
              <ExternalLink size={14} />
              View All
            </Link>
          </div>
          {spaces.map((space) => (
            <div
              key={space.id}
              className="space-option"
              onClick={() => handleAddToSpace(space.id)}
            >
              {space.title}
            </div>
          ))}
        </div>
      )}
      </div>
    );
  };

    // Memoize the container className calculation
    const containerClassName = useMemo(() => {
      return `messages-container ${isSidebarOpen || isHoverOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-open' : ''}`;
    }, [isSidebarOpen, isHoverOpen, isResourcesVisible]);
  

    // Add this new state
    const [showNamePrompt, setShowNamePrompt] = useState(false);

    // Add this useEffect to check if we need to show the name prompt
    useEffect(() => {
      setShowNamePrompt(Boolean(user?.id && !user?.s_name));
    }, [user?.id, user?.s_name]); // Only depend on specific user properties
  // In your main Chats component, add this function
  const handleAddToSpace = (spaceId) => {
    // Refresh the chats data after adding to space
    queryClient.invalidateQueries('chats');
    
    // Navigate to the space chat view
    navigate(`/ss/${chatId}?spaceId=${spaceId}`);
  };

  // Add the button to your message header or chat header section
  // Find where you render the chat header and add:


  // Add this new handler
  const handleAddArtifactToSpace = async (resource) => {
    try {
      const wrappedContent = wrapContentInArtifactTags(resource);
      if (!wrappedContent) {
        showCustomToast('error', 'Failed to process resource');
        return;
      }

      if (!spaceId) {
        showCustomToast('error', 'No space ID found');
        return;
      }

      await addArtifactToSpace(spaceId, wrappedContent);
      showCustomToast('success', 'Added to space successfully');
          } catch (error) {
      console.error('Failed to add artifact to space:', error);
      showCustomToast('error', 'Failed to add to space');
    }
  };

  // Add this helper function
  const wrapContentInArtifactTags = (resource) => {
    let wrappedContent = '<artifacts>\n';
    wrappedContent += `<chatid>${chatId}</chatid>\n`;
    
    switch (resource.type) {
      case 'essay':
        wrappedContent += `<essay>\n<title>${resource.title}</title>\n<content>${resource.content}</content>\n</essay>`;
        break;
      case 'code':
        wrappedContent += `<code language="${resource.language}" title="${resource.title}">${resource.content}</code>`;
        break;
      case 'visualization':
        wrappedContent += `<visualization title="${resource.title}">${resource.content}</visualization>`;
        break;
      default:
        console.error('Unknown resource type:', resource.type);
        return null;
    }
    
    wrappedContent += '\n</artifacts>';
    return wrappedContent;
  };

  // Update the useEffect that handles URL parameters and state
  useEffect(() => {
    // Handle immediate redirection for space chats
    if (chatId && spaceId && !isSpaceChat) {
      navigate(`/ss/${chatId}?spaceId=${spaceId}`, { replace: true });
      return;
    }

    // Rest of your existing URL parameter handling code
    const resourceTitle = searchParams.get('resource');
    const version = searchParams.get('version');
    const stateTitle = location.state?.title;
    
    // If we have a title from either source, find and open the resource
    const titleToFind = resourceTitle || stateTitle;
    
    if (titleToFind && chatId && resources[chatId]) {
      const resource = resources[chatId].find(r => r.title === titleToFind);
      if (resource) {
        setExpandedResource(resource);
        setIsResourcesExpanded(true);
        setExpandedVersion(version || '0');
        
        // Always clear the navigation state
        window.history.replaceState({}, document.title);
        
        // Update URL parameters if they're not already set
        if (!resourceTitle) {
          const newParams = new URLSearchParams(searchParams);
          newParams.set('resource', titleToFind);
          if (version) newParams.set('version', version);
          setSearchParams(newParams);
        }
      }
    }
  }, [chatId, spaceId, isSpaceChat, navigate, searchParams, setSearchParams, location.state?.title, resources]); // Add necessary dependencies

  const PDFProcessingMessage = () => (
    <div className="pdf-processing-message">
      <div className="processing-content">
        <div className="processing-icon">
          <RefreshCw className="spin-animation" />
        </div>
        <div className="processing-text">
          <h4>Analyzing File</h4>
          <p>Please wait while we process your document...</p>
        </div>
      </div>
      <div className="processing-progress">
        <div className="progress-bar"></div>
      </div>
    </div>
  );

  // Add this useEffect to handle clicks outside the sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (window.innerWidth <= 568) {  // Only on mobile devices
        const sidebar = document.querySelector('.sidebar');
        const chatHeader = document.querySelector('.chat-header');
        
        // Check if click is outside sidebar and chat header
        if (sidebar && 
            chatHeader && 
            !sidebar.contains(event.target) && 
            !chatHeader.contains(event.target)) {
          setIsSidebarOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

    // Add early return with loading state
    if (authLoading) {
      return (
        <div className="chat-loading">
          <LoadingOverlay />
        </div>
      );
    }

  // if (isLoading) {
  //   return (
  //     <div className="chat-area">
  //       <div className="chat-header">
  //         <div className="loading-header" />
  //       </div>
  //       <LoadingMessages />
  //     </div>
  //   );
  // }



  // Add this function to handle name submission
  const handleNameSubmit = async (name) => {
    try {
      // Only update if name is provided and not empty
      if (!name?.trim()) {
        showCustomToast('error', 'Please enter a valid name');
        return;
      }

      // Create profile update object with existing data
      const profileData = {
        name: user?.name || '', // Keep existing name
        phone_number: user?.phone_number || '', // Keep existing phone number
        s_name: name.trim() // Update study name
      };

      await updateProfile(profileData);
      
      // Update local user object
      if (user) {
        user.s_name = name.trim();
      }
      
      setShowNamePrompt(false);
    } catch (error) {
      console.error('Error updating name:', error);
      showCustomToast('error', 'Failed to update name preference');
    }
  };

  // Add this component inside Chats component
// Add this component near your other component declarations
const NamePromptModal = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim().length < 2) {
      setError('Name must be at least 2 characters');
      return;
    }
    onSubmit(name);
  };

  if (!isOpen) return null;

  return (
    <div className="name-prompt-overlay">
      <div className="name-prompt-modal">
        <h2>Hello!</h2>
        <p>What should I call you?</p>
        
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setError('');
            }}
            placeholder="Enter your name"
            autoFocus
            maxLength={50}
          />
          {error && <div className="name-prompt-error">{error}</div>}
          
          <div className="name-prompt-buttons">
            <button type="submit" className="name-prompt-button primary">
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};



  return (
    <div 
      className={`chat-page ${isSidebarOpen || isHoverOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-open' : ''}`}
      {...getRootProps()}
    >
      {showToast && (
        <MemoizedCustomToast
          message={toastMessage}
          type={toastType}
          onClose={() => setShowToast(false)}
        />
      )}

      {user && (
        <div 
          className={`sidebar-trigger-area ${isSpaceChat ? 'space-chat' : ''}`}
          onMouseEnter={handleHoverOpen}
          onMouseLeave={handleHoverClose}
        />
      )}
    <Helmet>
      <title>StudyBuddy</title>
      <meta name="description" content="StudyBuddy is your personal AI tutor powered by advanced language models. Get instant help with homework, exam prep, and learning across all subjects." />
      
      {/* Enhanced keywords */}
      <meta name="keywords" content="StudyBuddy, Cognora AI, artificial intelligence tutor, intelligent tutoring, homework help, online tutoring, educational chatbot, study assistant, exam preparation, personalized learning, AI education, virtual tutor, academic support, intelligent tutoring system, machine learning education, ai chat, ai, study buddy, study bdy, study buddy 3, study buddy login, study buddy website, study buddy, study bddy, chat cognora, chat, chatai, chatbot study buddy, studyb, studybuddy login, studybuddy, study buddy chat, cognora, cognora chat, cognora studybuddy, cognora" />
      {/* Additional meta tags */}
      <meta property="og:title" content="StudyBuddy - Your AI-Powered Learning Companion" />
      <meta property="og:description" content="Experience personalized tutoring with our advanced AI technology. Get instant help with any subject, 24/7." />
      
      {/* Primary meta tags */}
      <link rel="canonical" href={`https://studdybuddy.ca/`} />
      
      {/* OpenGraph tags */}
      <meta property="og:title" content={`StudyBuddy`} />
      <meta property="og:description" content="Talk with StudyBuddy, your AI-powered study companion" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://studdybuddy.ca" />
      
      {/* Additional SEO meta tags */}
      <meta name="author" content="Cognora" />
      <meta name="robots" content="index, follow" />
      
      {/* Schema.org markup enhancement */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "SoftwareApplication",
          "name": "StudyBuddy",
          "applicationCategory": "EducationalApplication",
          "creator": {
            "@type": "Organization",
            "name": "Cognora",
            "url": "https://cognora.ca"
          },
          "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
          },
          "description": "AI-powered educational platform by Cognora offering personalized tutoring and academic support",
          "featureList": [
            "Advanced AI tutoring technology",
            "Interactive learning interface",
            "PDF analysis and comprehension",
            "Multiple learning modes",
            "Personalized study assistance",
            "Real-time AI responses"
          ]
        })}
      </script>
    </Helmet>

    {/* Enhanced visually hidden headings */}
    <h1 className="visually-hidden">StudyBuddy by Cognora: Leading AI-Powered Educational Assistant</h1>
    <h2 className="visually-hidden">Next-Generation Learning with Cognora's AI Technology</h2>

    {/* Enhanced hidden semantic content */}
    <div className="visually-hidden">
      <h3>Cognora's Advanced AI Learning Features</h3>
      <ul>
        <li>Powered by Cognora's proprietary AI technology</li>
        <li>Integration with GPT, Claude, and Gemini AI</li>
        <li>Industry-leading educational AI platform</li>
        <li>Advanced natural language processing for education</li>
        <li>Premium alternative to traditional tutoring services</li>
      </ul>
      
      <h3>Comprehensive Educational Support</h3>
      <ul>
        <li>Advanced STEM subjects and humanities support</li>
        <li>Professional academic writing assistance</li>
        <li>Standardized test preparation</li>
        <li>Research methodology guidance</li>
        <li>Custom learning path development</li>
      </ul>
    </div>

      {showSalesModal && (
        <SalesModal
          feature={salesModalFeature}
          onClose={closeSalesModal}
          subscription={subscription}
        />
      )}

      <input {...getInputProps()} />
      {/* Sidebar Component */}
        {MemoizedSidebar}
        {/* ... rest of your JSX ... */}
      
      {/* Chat Header */}
      
      <div className={`chat-header ${(isSidebarOpen || isHoverOpen) ? 'sidebar-open' : ''}`}>
        <div className="header-left">
          {(!user) ? (
            <div className="logo-text">StudyBuddy</div>
          ) : (
            <>
              {isSpaceChat ? (
                <>
                  <button
                    className="return-chat-button"
                    onClick={() => navigate(`/study-spaces/${spaceId}`)}
                    aria-label="Return to space"
                  >
                    <ArrowLeft01Icon size={24} style={{ marginRight: '0px' }} />
                    Space
                    <span className="hover-text bottom">Return to Space</span>
                  </button>
                  {filteredMessages?.length > 0 && (
                    <button
                      className="new-chat space"
                      onClick={() => navigate(`/ss/new?spaceId=${spaceId}`)}
                      aria-label="Return to space"
                    >
                          <LicenseDraftIcon size={22} />
                      <span className="hover-text chat">Start a New Space Chat</span>
                    </button>
                  )}
                </>
              ) : (
                <div>
                  {!isSidebarOpen && !isHoverOpen && (
                    <div className="header-buttons">
                      <button className="menu-button" onClick={toggleSidebar} onMouseEnter={handleHoverOpen} onMouseLeave={handleHoverClose}>
                        <SidebarLeftIcon size={22} style={{marginLeft: '15px', marginRight: '-15px' }} />
                      </button>
                      <button 
                        className="new-chat chat" 
                        onClick={handleAddChat}
                        aria-label="Start new chat"  
                      >
                        <LicenseDraftIcon size={22} />
                        <span className="hover-text chat">Start a New Chat</span>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          
        </div>
        
          <div className="header-center">
          {!isSpaceChat && searchEnable && renderHeaderCenter()}

          </div>

        <div className="header-right">
          {user ? (
            <>
                {!isSpaceChat && (
                  <AddToSpaceButton 
                    chatId={chatId} 
                    onAdd={handleAddToSpace}
                  />
                )}
              <button 
                className={`chat-controls-toggle ${isResourcesVisible ? 'active' : ''}`}
                onClick={toggleResources}
              >
                <SlidersVerticalIcon size={21} />
                 <span className="hover-text resources">{isResourcesVisible ? 'Close Resources' : 'Open Resources'}</span>
              </button>
              <UserDropdown user={user} handleLogout={handleLogout} />
            </>
          ) : (
            <>
                <Link 
                  to="/signup" 
                  className="auth-button" 
                >
                  Sign Up
                </Link>
              <Link 
                to="/login" 
                className="auth-button sign-in"
              >
                Login
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Add the mobile search overlay */}
      <MobileSearchOverlay
        isOpen={isSearchOverlayOpen}
        onClose={() => setIsSearchOverlayOpen(false)}
        searchQuery={searchQuery}
        onSearch={handleSearch}
        searchResults={searchResults}
        onResultClick={handleSearchResultClick}
      />

      {/* Chat Area */}
      <div className={`chat-area ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'} ${isResourcesVisible ? 'resources-open' : ''} ${isResourcesExpanded ? 'resources-expanded' : ''}`}>
        {/* Messages Container */}
        <div 
          className={containerClassName}
          ref={messagesContainerRef}
        >
          <div 
            className="messages-wrapper"
            style={{ overscrollBehavior: 'contain' }}
          >
            <div className="messages-content">
              { !isInitialLoading && filteredMessages && filteredMessages?.length === 0 && !editedMessageId? (
                searchQuery && window.innerWidth > 768 ? (
                  <div className="no-results">No messages found matching your search.</div>
                ) : (
                  isDefaultDisplay && !isGeneratingResponse && !editedMessageId ? renderDefaultDisplay() : null
                )
              ) : (
                <>
                  {filteredMessages && filteredMessages.map((message, index, array) => renderMessage(message, index, array))}
                </>
              )}
              {searchQuery && searchResults?.length > 0 && window.innerWidth > 768 && (
                <OtherChatsSearchResults 
                  results={searchResults}
                  onResultClick={handleSearchResultClick}
                />
              )}
              {isGeneratingResponse ? (
                <div className="message ai">
                  <img src={studyBuddyLogo} alt="StudyBuddy AI" className="ai-icon" />
                  <div className="message-content">
                  {isPDFProcessing && (
                    <PDFProcessingMessage />
                  )}
                    
                    <div className="message-content-wrapper">
                      <AIResponseRenderer content={generatedContent} renderResource={renderResource} messageId={regeneratingMessageId} />
                    </div>
                    {!isPDFProcessing && renderLoadingSymbol()}
                  </div>
                </div>
              ) : null}
              <div ref={messagesEndRef} style={{ height: '30px' }} /> {/* Make sure this is at the very end */}
            </div>
            <ResourcesSection
              isLoadingResource={isLoadingResource}
              renderExpanded={renderExpanded}           
              ExpandedResource={ExpandedResource}
              isVisible={isResourcesVisible}
              projectTitle={currentChatTitle}
              isExpanded={isResourcesExpanded}
              setIsExpanded={setIsResourcesExpanded}
              onClose={closeResources}
              resources={resources[chatId] || []}  // Pass the resources specific to the current chat
              onResourceClick={handleResourceClick}
              expandedResource={expandedResource}
              setExpandedResource={setExpandedResource}
              showCustomToast={showCustomToast}
            />
          </div>
        </div>

        {pdfFiles?.length > 0 && (filteredMessages && filteredMessages?.length > 0) ? (
          <div className="pdf-preview">
            {pdfFiles.map(pdf => (
              <div key={pdf.id} className="pdf-preview-content">
                <FaFilePdf className="pdf-icon" />
                <span className="pdf-name">{pdf.name}</span>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    removePDF(pdf.id);
                  }} 
                  className="remove-pdf"
                  aria-label="Remove PDF"
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        ) : null}

      {showAuthPrompt && (
        <div className="auth-prompt">
          <AlertCircle size={24} />
          <div className="auth-prompt-content">
            <button 
              className="close-auth-prompt"
              onClick={() => setShowAuthPrompt(false)}
              aria-label="Close auth prompt"
            >
              <FaTimes />
            </button>
            <p>Please sign up or log in to continue messaging</p>
            <div className="auth-prompt-buttons">
              <Link to="/signup" className="auth-prompt-button signup">
                Sign Up
              </Link>
              <Link to="/login" className="auth-prompt-button login">
                Log In
              </Link>
            </div>
          </div>
        </div>
      )}


        {user && remainingChats!= null && remainingChats < 1 && filteredMessages?.length > 0 && renderChatLimitBanner()}
        {/* Input Container */}
        <div 
          className={`input-container ${isResourcesVisible ? 'resources-open' : ''} ${isDragActive ? 'dragging' : ''} ${!user ? 'auth-disclaimer' : ''}`}
        >
          {filteredMessages && (filteredMessages?.length > 0) ? (
            <>
              <div className={`input-area ${!user ? 'auth-disclaimer' : ''}`}>
                <form className={`input-wrapper chat ${user ? '' : 'nouser'}`} onSubmit={handleSubmit}>
                  <textarea
                    ref={(el) => {
                      textareaRef.current = el;
                      if (el) {
                        el.value = input;
                        inputRef.current = input;
                        adjustTextareaHeight();
                      }
                    }}
                    value={input}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (!handleCharacterLimit(newValue?.length)) {
                        setInput(newValue);
                        inputRef.current = newValue;
                        adjustTextareaHeight();
                      }
                    }}
                    onKeyDown={handleKeyDown}
                    onInput={adjustTextareaHeight}
                    placeholder="Type your message here..."
                    aria-label="Chat input"
                    className="chat-input scrollable"
                    disabled={isSubmitting}
                    autoFocus={filteredMessages?.length > 0}
                  />

                  <div className="input-button-wrapper">
                    <div className="input-button-wrapper-left">
                      <button
                        type="button"
                        className="attach-button empty"
                        onClick={handleAttachClick}
                        aria-label="Attach files"
                        title="Attach files"
                        disabled={!user}
                      >
                        <Attachment02Icon size={21} />
                        <span className="hover-text top">
                          {user ? 'Upload up to 3 files (PDFs, Images, Documents)' : 'Sign in to Upload Files'}
                        </span>
                      </button>
                    
                      <input
                        type="file"
                        accept={Object.keys(ACCEPTED_FILE_TYPES).join(',')}
                        multiple
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        id="file-upload"
                      />

                      <div className="mode-selector" ref={dropdownRef}>
                        <button 
                          type="button" 
                          className="mode-selector-button" 
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDropdownOpen(!isDropdownOpen);
                          }}
                          aria-label={`Select chat mode. Current mode: ${currentMode}`}
                          title={`Select chat mode. Current mode: ${currentMode}`}
                        >
                          {currentMode.charAt(0).toUpperCase() + currentMode.slice(1)} Mode
                          <ChevronDown size={16} />
                        </button>

                        {isDropdownOpen && (
                          <div className={`mode-dropdown ${isDropdownOpen ? 'active' : ''} ${window.innerWidth < 600 ? '' : 'large'}`}>
                            <div 
                              className="mode-option mode-qa" 
                              onClick={(e) => {
                                e.stopPropagation();
                                selectMode('qa');
                              }}
                              aria-label="Switch to Q&A mode"
                              title="Switch to Q&A mode"
                            >
                              Q&A
                            </div>
                            <div 
                              className="mode-option mode-tutor" 
                              onClick={(e) => {
                                e.stopPropagation();
                                selectMode('tutor');
                              }}
                              aria-label="Switch to Tutor mode"
                              title="Switch to Tutor mode"
                            >
                              Tutor
                            </div>
                            <div 
                              className="mode-option mode-brainstorm" 
                              onClick={(e) => {
                                e.stopPropagation();
                                selectMode('brainstorm');
                              }}
                              aria-label="Switch to Brainstorm mode"
                              title="Switch to Brainstorm mode"
                            >
                              Brainstorm
                            </div>
                            <div 
                              className="mode-option mode-debate" 
                              onClick={(e) => {
                                e.stopPropagation();
                                selectMode('debate');
                              }}
                              aria-label="Switch to Debate mode"
                              title="Switch to Debate mode"
                            >
                              Debate
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="input-button-wrapper-right">
                      <button 
                        type="submit" 
                        disabled={
                          isSubmitting || 
                          isInputProcessing || 
                          (!input.trim()) || 
                          (remainingChats <= 0 && remainingChats != null)
                        } 
                      >
                        <UploadCircle01Icon size={34} style={{paddingRight: '2px', color: 'var(--text-primary)', backgroundColor: 'var(--background-light)'}} />
                        <span className="hover-text top">
                          {isSubmitting ? "Sending..." :
                          isInputProcessing ? "Processing..." :
                          !input.trim() ? "Enter a message" :
                          remainingChats <= 0 && remainingChats != null ? "No chats left" : "Send A Message"}
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          ) : null}

        </div>
        {!user && (
          <div className={`auth-disclaimer ${filteredMessages && filteredMessages?.length > 0 ? 'has-messages' : ''}`}>
            <p>Chats are not saved in guest mode. Sign in to unlock full chat capabilities and get personalized learning.</p>
            {filteredMessages?.length === 0 && (
              <p>By sending a message, you agree to our <a href="https://cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
            )}
          </div>
        )}
            {showScrollButton && !isDefaultDisplay && (
              <button 
                className="scroll-to-bottom" 
                onClick={() => {
                  scrollToBottom();
                  setAutoScroll(true);
                }} 
                aria-label="Scroll to bottom"
              >
                <ChevronDown size={20} />
              </button>
            )}
      </div>

      {isDragActive && (
        <div className="pdf-drop-overlay">
          <div className="pdf-drop-content">
            <h3>Drop your files here</h3>
            <p>Release to attach PDFs, images or documents to your chat</p>
          </div>
        </div>
      )}

      {/* Add this new component for the auth prompt */}


      {/* Update the error handling condition */}
      {(!chatExists && isChatsLoaded) || messagesError?.isNotFoundError || messagesError?.isAuthError ? (
        <ChatNotFound />
      ) : (
        <>
          {/* Rest of your existing JSX */}

        </>
      )}
      <NamePromptModal 
        isOpen={showNamePrompt} 
        onSubmit={handleNameSubmit}
      />
    </div>
  );
};

export default React.memo(Chats);
