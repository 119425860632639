import React from 'react';
import { Link } from 'react-router-dom';
import CognoraLogo from '../../../assets/Cognora Logo High Res (1).png';

const AuthLayout = ({ children, title }) => {
  return (
    <div className="auth-container">
      <div className="branding">
        <Link to="/" className="logo">
          <img src={CognoraLogo} alt="Cognora Logo" className="cognora-logo" />
        </Link>
      </div>
      <div className="auth-form">
        <h2 className="auth-title">{title}</h2>
        {children}
      </div>
      <footer className="auth-footer">
        <a href="//cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        <span className="separator">|</span>
        <a href="//cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

export default AuthLayout; 