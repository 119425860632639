import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import '../Styles/PaymentConfirmation.css';
import useDocumentTitle from '../UseDocumentTitle';
import { motion, AnimatePresence } from 'framer-motion';

const WelcomePage = () => {
  useDocumentTitle('Welcome to StudyBuddy');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { checkUser, user } = useAuth();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    queryClient.clear();
    checkUser();

    const countdownTimer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate('/new');
    }, 5000);

    return () => {
      clearInterval(countdownTimer);
      clearTimeout(redirectTimer);
    };
  }, [navigate, queryClient, checkUser]);

  return (
    <motion.div
      className="welcome-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      
      <motion.div
        className="welcome-content"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ 
          type: "spring",
          stiffness: 100,
          delay: 0.2
        }}
      >
        <AnimatePresence>
          <motion.div 
            className="welcome-header"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <h1>Welcome{user?.name ? `, ${user.name}!` : '!'}</h1>
            {user?.subscription && (
              <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                Your {user.subscription.tier.charAt(0).toUpperCase() + user.subscription.tier.slice(1)} journey begins now
              </motion.h2>
            )}
          </motion.div>

          <motion.p 
            className="welcome-message"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            Preparing your personalized experience...
          </motion.p>

          <div className="progress-container">
            <motion.div 
              className="progress-bar"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1 }}
            >
              <motion.div 
                className="progress"
                initial={{ width: "0%" }}
                animate={{ width: `${(5 - countdown) * 20}%` }}
                transition={{ duration: 1, ease: "easeInOut" }}
              />
            </motion.div>
          </div>

          <motion.button
            className="start-button"
            onClick={() => navigate('/new')}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2 }}
          >
            Start Now
          </motion.button>
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default WelcomePage;