import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../services/api';
import AuthLayout from './Layout/AuthLayout';
import { FormInput, SubmitButton, FormError, FormSuccess } from './Layout/FormInput';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setIsLoading(true);

    try {
      const response = await forgotPassword(email);
      setMessage(response.message);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Memoize email change handler
  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  return (
    <AuthLayout title="Forgot Password">
      <form onSubmit={handleSubmit}>
        <FormError message={error} />
        <FormSuccess message={message} />
        
        <FormInput
          key="email-input"
          label="Email"
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          disabled={isLoading}
          required
        />

        <SubmitButton isLoading={isLoading}>
          Reset Password
        </SubmitButton>
      </form>

      <p className="auth-link">
        Remember your password? <Link to="/login">Log in</Link>
      </p>
    </AuthLayout>
  );
};

export default ForgotPassword;
