import React from 'react';
import '../../Styles/LoadingOverlay.css';

const LoadingOverlay = ({ message = "Loading...", isLoading = true }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay" role="alert" aria-busy="true">
      <div className="loading-content">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

export default LoadingOverlay;