import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import LoadingOverlay from '../Partials/LoadingOverlay';

const PrivateRoute = ({ children, requiresElite = false }) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login');
      } else if (requiresElite && (!user?.tier || user.tier === 'free' || user.tier === 'premium')) {
        navigate('/pricing#study-spaces');
      }
    }
  }, [isAuthenticated, isLoading, navigate, requiresElite, user]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (requiresElite && (!user?.tier || user.tier === 'free' || user.tier === 'premium')) {
    return null;
  }

  return isAuthenticated ? children : null;
};

export default PrivateRoute;