import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import { login as apiLogin, logout as apiLogout, updateUserMemory, getInitialPayload } from '../services/api';
import { useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [memoryArray, setMemoryArray] = useState([]);
  const [isCheckingUser, setIsCheckingUser] = useState(true);
  const [spaces, setSpaces] = useState([]);
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  // eslint-disable-next-line no-unused-vars
  const { data: initialData } = useQuery(
    'initialPayload',
    getInitialPayload,
    {
      enabled: !!localStorage.getItem('token'),
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      onSuccess: (data) => {
        setUser(data.profile);
        setSpaces(data.spaces.results || []);
        setChats(data.chats.results || []);
        setSubscription(data.profile.tier);
        setMemoryArray(data.profile.memoryArray || []);
        setIsAuthenticated(true);
        setIsLoading(false);
        setIsCheckingUser(false);

        queryClient.setQueryData('spaces', data.spaces.results);
        queryClient.setQueryData(['chats', data.profile.id], {
          chats: data.chats.results,
          subscription: data.profile.subscription
        });
      },
      onError: () => {
        logout();
        setIsLoading(false);
        setIsCheckingUser(false);
      },
      retry: false
    }
  );

  const checkUser = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsAuthenticated(false);
      setIsLoading(false);
      setIsCheckingUser(false);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      checkUser();
    }, 100);

    return () => clearTimeout(timer);
  }, [checkUser]);

  const login = async (email, password) => {
    try {
      const userData = await apiLogin(email, password);
      setUser(userData.profile);
      setIsAuthenticated(true);
      queryClient.invalidateQueries('initialPayload');
      return userData;
    } catch (error) {
      console.error('Login error in useAuth:', error);
      if (error.response) {
        throw new Error('Invalid email or password');
      } else if (error.request) {
        throw new Error('We\'re having trouble connecting to our servers. Please check your internet connection and try again in a few minutes.');
      } else {
        throw new Error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const logout = useCallback(() => {
    apiLogout();
    setUser(null);
    setIsAuthenticated(false);
    setSubscription(null);
    setSpaces([]);
    setChats([]);
    setMemoryArray([]);

    queryClient.clear();

    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('chat_')) {
        localStorage.removeItem(key);
      }
    });

    navigate('/');
    
    localStorage.removeItem('token');
  }, [queryClient, navigate]);

  const updateMemory = async (newMemoryArray) => {
    try {
      await updateUserMemory(newMemoryArray);
      setMemoryArray(newMemoryArray);
      queryClient.invalidateQueries('profile');
    } catch (error) {
      console.error('Error updating memory:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      isAuthenticated, 
      login, 
      logout, 
      checkUser, 
      isLoading: globalLoading || isLoading || isCheckingUser, 
      setGlobalLoading,
      subscription,
      memoryArray,
      updateMemory,
      spaces,
      chats,
      refetchInitialData: () => queryClient.invalidateQueries('initialPayload')
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);