import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useTheme } from '../../App';
import { Copy01Icon, Tick01Icon } from 'hugeicons-react';

const preprocessLatex = (content) => {
  return content;
};

// Remove or modify fixLaTeXBackslashes function as it may interfere
const fixLaTeXBackslashes = (content) => {
  // Only fix backslashes outside of matrix environments
  const parts = content.split(/(\\begin\{pmatrix\}[\s\S]*?\\end\{pmatrix\})/g);
  return parts.map((part, index) => {
    // Skip matrix blocks (odd indices)
    if (index % 2 === 1) return part;
    // Fix other backslashes
    return part.replace(/\\\\/g, '\\');
  }).join('');
};

// Memoize the MarkdownRenderer component
export const MarkdownRenderer = React.memo(({ content, allowedElements, disallowedElements, inline }) => {
  const processedContent = useMemo(() => {
    let newContent = processChemistryInLine(content);
    newContent = preprocessLatex(newContent);
    newContent = fixLaTeXBackslashes(newContent);
    return newContent;
  }, [content]);

  const components = useMemo(() => ({
    // This will render disallowed elements as normal text
    ...(disallowedElements || []).reduce((acc, el) => ({
      ...acc,
      [el]: ({ node, ...props }) => <span {...props} />
    }), {}),
    // This will only allow rendering of allowed elements
    ...(allowedElements || []).reduce((acc, el) => ({
      ...acc,
      [el]: props => React.createElement(el, props)
    }), {}),
    // Handle math rendering
    math: ({ value }) => <span className="math">{value}</span>,
    inlineMath: ({ value }) => <span className="inline-math">{value}</span>,
  }), [allowedElements, disallowedElements]);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeRaw, [rehypeKatex, { output: 'htmlAndMathml' }]]}
      components={components}
    >
      {processedContent}
    </ReactMarkdown>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function for React.memo
  return (
    prevProps.content === nextProps.content &&
    JSON.stringify(prevProps.allowedElements) === JSON.stringify(nextProps.allowedElements) &&
    JSON.stringify(prevProps.disallowedElements) === JSON.stringify(nextProps.disallowedElements) &&
    prevProps.inline === nextProps.inline
  );
});

const PhysicsRenderer = ({ content }) => {
  // Custom rendering logic for physics content
  return <div className="physics-content">{content}</div>;
};

const ChemistryRenderer = ({ content }) => {
  // Custom rendering logic for chemistry content
  return <div className="chemistry-content">{content}</div>;
};

const BiologyRenderer = ({ content }) => {
  // Parse the LaTeX-like content
  const title = content.match(/title=(.*?)\]/)?.[1] || 'Biology Content';
  const bodyContent = content.replace(/\\begin\{tcolorbox\}.*?\n([\s\S]*?)\\end\{tcolorbox\}/s, '$1');

  // Convert LaTeX-like formatting to JSX
  const formattedContent = bodyContent
    .replace(/\\textbf\{(.*?)\}/g, '<strong>$1</strong>')
    .replace(/\\xrightarrow\{(.*?)\}/g, ' → ')
    .replace(/\\\[(.*?)\\\]/g, '<MathRenderer math="$1" />')
    .replace(/\\\((.*?)\\\)/g, '<MathRenderer math="$1" inline />')
    .replace(/\$([^$]+)\$/g, '<MathRenderer math="$1" inline />')
    .replace(/•/g, '•')
    .split('\n\n');

  return (
    <div className="biology-content" style={{ border: '2px solid green', padding: '10px', borderRadius: '5px' }}>
      <h3 style={{ color: 'green' }}>{title}</h3>
      {formattedContent.map((paragraph, index) => (
        <span key={index}>
          <MarkdownRenderer content={paragraph} />
        </span>
      ))}
    </div>
  );
};

const processChemistryInLine = (line) => {
  return line
    // Ensure \ce{...} has the backslash
    .replace(/<span class="inline-chemistry">\\?ce\{([^}]+)\}<\/span>/g, (match, p1) => {
      return `\\ce{${p1}}`;
    })
    // Wrap \ce{...} with inline math delimiters $...$
    .replace(/\\ce\{([^}]+)\}/g, '$\\ce{$1}$')
    // Remove trailing line breaks within inline math
    .replace(/\$\\ce\{([^}]+)\}\$\\+/g, '$\\ce{$1}$')
    .replace(/\$\\(\w+)\{([^}]+)\}\$\\+/g, '$\\$1{$2}$');
};

const parseResponse = (text) => {
  // eslint-disable-next-line no-unused-vars
  let globalOrderedListCount = 1; // Initialize global counter
  const lines = text.split('\n');
  const parsedContent = [];
  let currentList = null;
  let currentListType = null; // Tracks list type
  let currentListItem = null;
  let inCodeBlock = false;
  let currentCodeBlock = null;
  let codeBlockLanguage = '';
  let codeBlockContent = '';
  let inTable = false;
  let tableHeaders = [];
  let tableRows = [];
  let inMathBlock = false;
  let mathBlockContent = '';
  let inPhysicsBlock = false;
  let physicsBlockContent = '';
  let inChemistryBlock = false;
  let chemistryBlockContent = '';
  let inBiologyBlock = false;
  let biologyBlockContent = '';
  let inListItemParagraph = false; // Flag for list item paragraphs
  let listItemParagraphContent = ''; // Content for list item paragraphs

  // Remove generation stopped markers
  text = text.replace(/\[Generation Stopped\]/g, '');

  const processMathExpression = (line) => {
    // Preserve inline and display math
    return line;
  };

  const processInlineCode = (line) => {
    const parts = line.split(/(`[^`]+`)/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return { type: 'inline-code', content: part.slice(1, -1) };
      }
      return { type: 'text', content: part };
    });
  };

  const processListItem = (line, listType) => {
    // Calculate indentation level based on leading spaces
    const indentMatch = line.match(/^\s*/)[0];
    const level = Math.floor(indentMatch.length / 2);
    const trimmedLine = line.trim();
    let content = '';
    let number = null;

    // Handle both numbered lists and bullet points
    if (listType === 'ordered') {
        const match = trimmedLine.match(/^(\d+)\.\s+(.*)/);
        if (match) {
            number = parseInt(match[1], 10);
            content = match[2];
        }
    } else {
        // Handle both • and regular bullet points
        const match = trimmedLine.match(/^([•\-*+])\s+(.*)/);
        if (match) {
            content = match[2];
        }
    }

    if (!content) return;

    // Process content and create new list item
    const parts = content.split(/(`[^`]+`)/);
    const processedParts = parts.map(part => {
        if (part.startsWith('`') && part.endsWith('`')) {
            return { type: 'inline-code', content: part.slice(1, -1) };
        }
        return { type: 'text', content: part };
    });

    const newItem = { 
        content: processedParts, 
        subitems: [], 
        paragraphs: [], 
        number,
        level // Keep the actual level without normalization
    };

    // Reset list context if level is 0
    if (level === 0) {
        currentList = null;
        currentListItem = null;
        currentListType = null;
    }

    // Handle list structure
    if (currentList && currentListType === listType) {
        if (level > currentList.level) {
            // This is a subitem
            if (currentListItem) {
                currentListItem.subitems.push(newItem);
            }
        } else {
            // Same level as current list
            currentList.items.push(newItem);
            currentListItem = newItem;
        }
    } else {
        // Start a new list
        currentList = { 
            items: [newItem], 
            isOrdered: listType === 'ordered',
            start: listType === 'ordered' ? number : null,
            level // Store the level with the list
        };
        parsedContent.push(currentList);
        currentListItem = newItem;
        currentListType = listType;
    }
    
    inListItemParagraph = false;
  };

  const finalizePreviousList = () => {
    if (currentList) {
      if (currentList.isOrdered) {
        globalOrderedListCount += currentList.items.length;
      }
      // Remove empty lists
      if (currentList.items.length === 0) {
        parsedContent.pop();
      }
      currentList = null;
      currentListItem = null;
      currentListType = null;
      inListItemParagraph = false;
      listItemParagraphContent = '';
    }
  };

  const finalizeListItemParagraph = () => {
    if (inListItemParagraph && currentListItem) {
      currentListItem.paragraphs.push(listItemParagraphContent.trim());
      listItemParagraphContent = '';
      inListItemParagraph = false;
    }
  };

  const cleanupSpaces = (content) => {
    return content.replace(/\s+([.,;:!?])/g, '$1');
  };

  const parseCodeBlockMetadata = (content) => {
    const titleMatch = content.match(/\[CODE_BLOCK_TITLE:\s*(.*?)\]/);
    const versionMatch = content.match(/\[VERSION:\s*(.*?)\]/);
    const previousVersionMatch = content.match(/\[PREVIOUS_VERSION:\s*(.*?)\]/);

    return {
      title: titleMatch ? titleMatch[1] : null,
      version: versionMatch ? versionMatch[1] : null,
      previousVersion: previousVersionMatch ? previousVersionMatch[1] : null,
    };
  };

  const parseEssayMetadata = (content) => {
    const titleMatch = content.match(/\[ESSAY_TITLE:\s*(.*?)\]/);
    const versionMatch = content.match(/\[VERSION:\s*(.*?)\]/);
    const previousVersionMatch = content.match(/\[PREVIOUS_VERSION:\s*(.*?)\]/);

    return {
      title: titleMatch ? titleMatch[1] : null,
      version: versionMatch ? versionMatch[1] : null,
      previousVersion: previousVersionMatch ? previousVersionMatch[1] : null,
    };
  };

  const processLine = (line, lineIndex) => {
    // Add this check before the existing list item checks
    const mcqMatch = line.match(/^\s*([a-z])\)\s+(.*)/i);
    if (mcqMatch) {
        finalizePreviousList();
        parsedContent.push({ 
            type: 'paragraph', 
            content: line.trim() 
        });
        return;
    }

    // Check for list items first, using the raw line
    const orderedListMatch = line.match(/^\s*(\d+)\.\s+(.*)/);
    const unorderedListMatch = line.match(/^\s*([•\-*+])\s+(.*)/);

    if (orderedListMatch) {
        processListItem(line, 'ordered');
        return;
    }

    if (unorderedListMatch) {
        processListItem(line, 'unordered');
        return;
    }

    // For non-list content, then we can trim
    const trimmedLine = line.trim();

    // Handle quotes first
    const quoteMatch = trimmedLine.match(/<quote>([\s\S]*?)<\/quote>/);
    if (quoteMatch) {
      finalizePreviousList();
      parsedContent.push({ 
        type: 'quote', 
        content: quoteMatch[1].trim() 
      });
      return;
    }

    if (inMathBlock) {
      if (trimmedLine === '\\]') {
        parsedContent.push({ type: 'math-block', content: mathBlockContent.trim() });
        inMathBlock = false;
        mathBlockContent = '';
      } else {
        mathBlockContent += line + '\n';
      }
      return;
    }

    if (trimmedLine === '\\[') {
      inMathBlock = true;
      return;
    }

    if (trimmedLine.startsWith('```')) {
      if (inCodeBlock) {
        if (codeBlockLanguage === 'essay') {
          const metadata = parseEssayMetadata(codeBlockContent);
          parsedContent.push({
            type: 'essay',
            content: codeBlockContent.replace(/\[.*?\]\n/g, '').trim(),
            title: metadata.title,
            version: metadata.version,
            previousVersion: metadata.previousVersion,
          });
        } else {
          const metadata = parseCodeBlockMetadata(codeBlockContent);
          parsedContent.push({
            type: 'code-block',
            content: codeBlockContent.replace(/\[.*?\]\n/g, '').trim(),
            language: codeBlockLanguage,
            title: metadata.title,
            version: metadata.version,
            previousVersion: metadata.previousVersion,
          });
        }
        inCodeBlock = false;
        codeBlockContent = '';
        codeBlockLanguage = '';
      } else {
        inCodeBlock = true;
        codeBlockLanguage = trimmedLine.slice(3).trim() || 'plaintext';
      }
      return;
    } else if (line.startsWith('<code language="')) {
      const languageMatch = line.match(/<code language="(\w+)">/);
      if (languageMatch) {
        inCodeBlock = true;
        currentCodeBlock = { language: languageMatch[1], content: '' };
      }
      return;
    } else if ((line.startsWith('</code>') || trimmedLine === '```') && inCodeBlock) {
      if (currentCodeBlock) {
        parsedContent.push({ type: 'code-block', ...currentCodeBlock });
        currentCodeBlock = null;
      }
      inCodeBlock = false;
      return;
    } else if (inCodeBlock) {
      if (currentCodeBlock) {
        currentCodeBlock.content += line + '\n';
      } else {
        codeBlockContent += line + '\n';
      }
      return;
    }

    // Handle other content types
    finalizePreviousList();
    finalizeListItemParagraph();

    // Handle headers using Markdown syntax
    const headerMatch = trimmedLine.match(/^(#{1,6})\s+(.*)/);
    if (headerMatch) {
      const level = headerMatch[1].length;
      const headerContent = headerMatch[2].trim();
      parsedContent.push({ type: 'heading', level, content: headerContent });
      return;
    }

    // Handle tables
    if (trimmedLine.startsWith('|') && trimmedLine.endsWith('|')) {
      if (!inTable) {
        inTable = true;
        tableHeaders = trimmedLine.split('|').slice(1, -1).map(header => header.trim());
      } else if (trimmedLine.includes('---')) {
        // Separator line, skip
      } else {
        tableRows.push(trimmedLine.split('|').slice(1, -1).map(cell => cell.trim()));
      }
      return;
    } else if (inTable) {
      // End of table
      parsedContent.push({ type: 'table', headers: tableHeaders, rows: tableRows });
      inTable = false;
      tableHeaders = [];
      tableRows = [];
    }

    // Handle physics and chemistry blocks
    if (trimmedLine.startsWith('$$physics$$')) {
      inPhysicsBlock = true;
      physicsBlockContent = '';
      return;
    } else if (inPhysicsBlock && trimmedLine === '$$endphysics$$') {
      parsedContent.push({ type: 'physics-block', content: physicsBlockContent.trim() });
      inPhysicsBlock = false;
      physicsBlockContent = '';
      return;
    } else if (inPhysicsBlock) {
      physicsBlockContent += line + '\n';
      return;
    }

    if (trimmedLine.startsWith('$$chemistry$$')) {
      inChemistryBlock = true;
      chemistryBlockContent = '';
      return;
    } else if (inChemistryBlock && trimmedLine === '$$endchemistry$$') {
      parsedContent.push({ type: 'chemistry-block', content: chemistryBlockContent.trim() });
      inChemistryBlock = false;
      chemistryBlockContent = '';
      return;
    } else if (inChemistryBlock) {
      chemistryBlockContent += line + '\n';
      return;
    }

    // Handle biology blocks
    if (trimmedLine.startsWith('Light-Independent Reactions') || inBiologyBlock) {
      if (!inBiologyBlock) {
        inBiologyBlock = true;
        biologyBlockContent = '\\begin{tcolorbox}[colback=green!5!white,colframe=green!75!black,title=Biology Content]\n';
      }
      biologyBlockContent += line + '\n';
      if (lineIndex === lines.length - 1 || lines[lineIndex + 1].trim() === '') {
        biologyBlockContent += '\\end{tcolorbox}';
        parsedContent.push({ type: 'biology-block', content: biologyBlockContent.trim() });
        inBiologyBlock = false;
        biologyBlockContent = '';
      }
      return;
    }

    // Handle horizontal rules
    if (trimmedLine === '---') {
      finalizePreviousList();
      parsedContent.push({ type: 'horizontal-rule' });
      return;
    }

    // Handle blockquotes
    if (trimmedLine.startsWith('> ')) {
      finalizePreviousList();
      parsedContent.push({ type: 'blockquote', content: trimmedLine.slice(2) });
      return;
    }

    // Handle links
    if (trimmedLine.match(/\[.*?\]\(.*?\)/)) {
      finalizePreviousList();
      const linkMatch = trimmedLine.match(/\[(.*?)\]\((.*?)\)/);
      parsedContent.push({ type: 'link', text: linkMatch[1], url: linkMatch[2] });
      return;
    }

    // Handle interrupted message
    if (trimmedLine.includes("[Generation stopped]")) {
      finalizePreviousList();
      parsedContent.push({ type: 'interrupted-message', content: 'This message was interrupted due to connection issues.' });
      return;
    }

    // Handle boldened text
    if (trimmedLine.match(/\*\*.*?\*\*/)) {
      finalizePreviousList();
      const parts = trimmedLine.split(/(\*\*.*?\*\*)/);
      parsedContent.push({
        type: 'paragraph',
        content: parts.map((part, index) => {
          if (part.startsWith('**') && part.endsWith('**')) {
            return { type: 'strong', content: part.slice(2, -2) };
          }
          return { type: 'text', content: part };
        }),
      });
      return;
    }

    // Handle paragraphs and other content
    const processedLine = processMathExpression(processChemistryInLine(line));

    if (processedLine.includes('<span class="inline-physics">') || processedLine.includes('<span class="inline-chemistry">')) {
      finalizePreviousList();
      parsedContent.push({ type: 'paragraph', content: processInlineCode(processedLine) });
    } else if (/^#+\s/.test(trimmedLine)) {
      finalizePreviousList();
      const level = trimmedLine.match(/^#+\s/)[0].length;
      const content = trimmedLine.replace(/^#+\s/, '');
      parsedContent.push({ type: 'heading', level, content });
    } else {
      finalizePreviousList();
      const parts = processedLine.split(/(`[^`]+`)/);
      const processedParts = parts.map(part => {
        if (part.startsWith('`') && part.endsWith('`')) {
          return { type: 'inline-code', content: part.slice(1, -1) };
        }
        return { type: 'text', content: part };
      });
      if (processedParts.some(part => part.type === 'inline-code')) {
        parsedContent.push({ type: 'paragraph', content: processedParts });
      } else {
        parsedContent.push({ type: 'paragraph', content: cleanupSpaces(processedLine) });
      }
    }

    // Add handling for display math blocks that start and end with $$
    if (trimmedLine.startsWith('$$') && trimmedLine.endsWith('$$')) {
      finalizePreviousList();
      parsedContent.push({ 
        type: 'math-block', 
        content: trimmedLine.slice(2, -2) // Remove $$ from start and end
      });
      return;
    }
  };

  lines.forEach((line, lineIndex) => processLine(line, lineIndex));

  finalizePreviousList();
  finalizeListItemParagraph(); // Finalize any remaining list item paragraphs

  return parsedContent;
};

const renderListItem = (item, index, isOrdered, level = 0) => {
    // Calculate effective level based on item's own level property
    const effectiveLevel = item.level || level;

    return (
        <li 
            key={index} 
            className={`ai-response-list-item level-${effectiveLevel}`}
            value={isOrdered ? item.number : undefined}
            style={{
                listStyleType: isOrdered ? 'decimal' : 'disc',
                marginLeft: window.innerWidth <= 768 
                    ? effectiveLevel >= 2 
                        ? `${effectiveLevel * 0.17}rem` 
                        : `${effectiveLevel * 0.5}rem`
                    : effectiveLevel >= 3
                        ? `${effectiveLevel * 0.55}rem`
                        : `${effectiveLevel * 0.75}rem`,
            }}
        >
            <div className="ai-response-list-item-content">
                {item.content.map((part, partIndex) => {
                    if (part.type === 'inline-code') {
                        return (
                            <div key={partIndex} className="ai-response-inline-code">
                                {part.content}
                            </div>
                        );
                    }
                    if (part.type === 'text') {
                        // Handle bold text with inline code
                        const parts = part.content.split(/(\*\*.*?\*\*)/g);
                        return parts.map((textPart, textPartIndex) => {
                            if (textPart.startsWith('**') && textPart.endsWith('**')) {
                                const innerContent = textPart.slice(2, -2);
                                // Check for inline code within bold text
                                const codeParts = innerContent.split(/(`.*?`)/g);
                                return (
                                    <strong key={`${partIndex}-${textPartIndex}`}>
                                        {codeParts.map((codePart, codePartIndex) => {
                                            if (codePart.startsWith('`') && codePart.endsWith('`')) {
                                                return (
                                                    <div key={codePartIndex} className="ai-response-inline-code">
                                                        {codePart.slice(1, -1)}
                                                    </div>
                                                );
                                            }
                                            return codePart;
                                        })}
                                    </strong>
                                );
                            }
                            return (
                                <span key={`${partIndex}-${textPartIndex}`}>
                                    <MarkdownRenderer 
                                        content={textPart}
                                        inline={true}
                                    />
                                </span>
                            );
                        });
                    }
                    return null;
                })}
            </div>
            
            {/* Render paragraphs if any */}
            {item.paragraphs && item.paragraphs.length > 0 && (
                <div className="ai-response-list-item-paragraphs">
                    {item.paragraphs.map((para, paraIndex) => (
                        <span key={paraIndex} className="ai-response-paragraph">
                            <MarkdownRenderer content={para} inline={true} />
                        </span>
                    ))}
                </div>
            )}

            {/* Update the sublist rendering */}
            {item.subitems && item.subitems.length > 0 && (
                isOrdered ? (
                    <ol className={`ai-response-sublist level-${effectiveLevel}`}>
                        {item.subitems.map((subitem, subIndex) =>
                            renderListItem(subitem, subIndex, isOrdered, effectiveLevel)
                        )}
                    </ol>
                ) : (
                    <ul className={`ai-response-sublist level-${effectiveLevel}`}>
                        {item.subitems.map((subitem, subIndex) =>
                            renderListItem(subitem, subIndex, false, effectiveLevel)
                        )}
                    </ul>
                )
            )}
        </li>
    );
};

// Add this custom theme object
const customTheme = {
  'code[class*="language-"]': {
    color: '#d6deeb',
    fontFamily: 'var(--font-mono)',
    direction: 'ltr',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    lineHeight: '1.5',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
  },
  'pre[class*="language-"]': {
    color: '#d6deeb',
    background: '#011627',
    fontFamily: 'var(--font-mono)',
    direction: 'ltr',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    lineHeight: '1.5',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    padding: '1em',
    margin: '0.5em 0',
    overflow: 'auto',
    borderRadius: '0.3em',
  },
  'comment': { color: '#637777', fontStyle: 'italic' },
  'prolog': { color: '#637777' },
  'doctype': { color: '#637777' },
  'cdata': { color: '#637777' },
  'punctuation': { color: '#c792ea' },
  'property': { color: '#80cbc4' },
  'tag': { color: '#7fdbca' },
  'boolean': { color: '#ff5874' },
  'number': { color: '#f78c6c' },
  'constant': { color: '#82aaff' },
  'symbol': { color: '#82aaff' },
  'deleted': { color: '#ff5874' },
  'selector': { color: '#c792ea' },
  'attr-name': { color: '#7fdbca' },
  'string': { color: '#ecc48d' },
  'char': { color: '#ecc48d' },
  'builtin': { color: '#82aaff' },
  'inserted': { color: '#80cbc4' },
  'operator': { color: '#c792ea' },
  'entity': { color: '#f78c6c' },
  'url': { color: '#66d9ef' },
  'variable': { color: '#addb67' },
  'atrule': { color: '#82aaff' },
  'attr-value': { color: '#c3e88d' },
  'function': { color: '#82aaff' },
  'class-name': { color: '#f78c6c' },
  'keyword': { color: '#c792ea' },
  'regex': { color: '#80cbc4' },
  'important': { color: '#c792ea', fontWeight: 'bold' },
};

// Update the CodeBlock component to use the custom theme
export const CodeBlock = ({ language, content }) => {
  const [copied, setCopied] = useState(false);
  const { resolvedTheme } = useTheme();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy code:', err);
    }
  };

  const uniqueContent = content;
  const lineCount = uniqueContent.split('\n').length;
  const showHeader = lineCount > 7;

  return (
    <div className={`modern-code-display ${!showHeader ? 'with-header' : ''}`}>
      <div className="code-header">
        <span className={`code-language ${!showHeader ? 'with-header' : ''}`}>{language}</span>
        <button onClick={handleCopy} className="copy-button sticky-copy-button">
          {copied ? <Tick01Icon size={16} /> : <Copy01Icon size={16} />}
        </button>
      </div>

      {resolvedTheme === 'dark' ? (
        <div style={{ width: '100%', overflow: 'auto' }}>
          <SyntaxHighlighter
            language={language}
            wrapLines={true}
            wrapLongLines={true}
            style={customTheme}
            customStyle={{
              margin: 0,
              borderRadius: showHeader ? '0 0 8px 8px' : '8px',
              padding: '1rem',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              width: '100%',
              fontFamily: 'var(--font-mono)',
              fontSize: '0.8rem',
              lineHeight: '1.7',
              backgroundColor: 'var(--background-light)',
              border: '1px solid var(--border-color)',
              boxShadow: 'var(--shadow-elevated)',
            }}
          >
            {uniqueContent}
          </SyntaxHighlighter>
        </div>
      ) : (
        <div style={{ width: '100%', overflow: 'auto' }}>
          <SyntaxHighlighter
            language={language}
            wrapLines={true}
            wrapLongLines={true}
            customStyle={{
              margin: 0,
              borderRadius: showHeader ? '0 0 8px 8px' : '8px',
              padding: '1rem',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              width: '100%',
              fontFamily: 'var(--font-mono)',
              fontSize: '0.8rem',
              lineHeight: '1.3',
              backgroundColor: 'var(--background-light)',
              border: '1px solid var(--border-color)',
            }}
          >
            {uniqueContent}
          </SyntaxHighlighter>
        </div>
      )}
    </div>
  );
};

// Create a new component for the parsed content
export const ParsedContent = React.memo(({ parsedContent }) => {
  const elementRefs = useRef({});

  const renderMarkdownContent = (content) => {
    const contentString = Array.isArray(content) ? content.map(part => part.content || '').join('') : content;
    // Use span instead of default p tags for inline markdown
    return <MarkdownRenderer content={contentString} inline={true} components={{ p: 'span' }} />;
  };

  return (
    <div className="ai-response-content">
      {parsedContent.map((item, index) => {
        const refKey = `${item.type}-${index}`;
        elementRefs.current[refKey] = elementRefs.current[refKey] || React.createRef();

        if (item.isOrdered !== undefined && item.items) {
          return item.isOrdered ? (
            <ol 
              key={index} 
              className="ai-response-list ai-response-ordered-list" 
              start={item.start}
              ref={elementRefs.current[refKey]}
            >
              {item.items.map((listItem, listIndex) =>
                renderListItem(listItem, listIndex, true, 1)
              )}
            </ol>
          ) : (
            <ul key={index} className="ai-response-list" ref={elementRefs.current[refKey]}>
              {item.items.map((listItem, listIndex) =>
                renderListItem(listItem, listIndex, false, 1)
              )}
            </ul>
          );
        }

        // Existing rendering logic for other types (heading, paragraph, etc.)
        switch (item.type) {
          case 'heading':
            return React.createElement(`h${item.level}`, {
              key: index,
              className: `ai-response-heading ai-response-heading-${item.level}`,
              children: <MarkdownRenderer content={item.content} />
            });
          case 'paragraph':
            return (
              <div key={index} className="ai-response-paragraph">
                {renderMarkdownContent(item.content)}
              </div>
            );
          case 'strong':
            return <strong key={index} className="ai-response-strong">{item.content}</strong>;
          case 'blockquote':
            return <blockquote key={index} className="ai-response-blockquote">{item.content}</blockquote>;
          case 'link':
            return <a key={index} href={item.url} target="_blank" rel="noopener noreferrer" className="ai-response-link">{item.text}</a>;
          case 'horizontal-rule':
            return <hr key={index} className="ai-response-hr" />;
          case 'table':
            return (
              <div className="table-wrapper" key={index}>
                <table className="ai-response-table">
                  <thead>
                    <tr>
                      {item.headers.map((header, headerIndex) => (
                        <th key={headerIndex}>
                          {renderMarkdownContent(header)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{renderMarkdownContent(cell)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );

          case 'physics-block':
            return <PhysicsRenderer key={index} content={item.content.toString()} />;
          case 'chemistry-block':
            return <ChemistryRenderer key={index} content={item.content.toString()} />;
          case 'biology-block':
            return <BiologyRenderer key={index} content={item.content.toString()} />;
          case 'code-block':
            return <CodeBlock key={index} language={item.language} content={item.content} />;
          case 'essay':
            return null; // Assuming essay rendering is handled elsewhere or intentionally omitted
          case 'interrupted-message':
            return (
              <div className="interrupted-message">
                <span>{item.content}</span>
              </div>
            );
          case 'quote':
            return (
                <div className="quote-content">
                  {item.content}
                </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
});

// Update AIDisplayRenderer to use the new ParsedContent component
export const AIDisplayRenderer = React.memo(({ content }) => {
  const parsedContent = useMemo(() => parseResponse(content), [content]);
  return <ParsedContent parsedContent={parsedContent} />;
});

export const AIResponseRenderer = ({ content, renderResource, messageId = null }) => {
  const [wasInterrupted, setWasInterrupted] = useState(false);
  const [processedContent, setProcessedContent] = useState([]);

  // Add default renderResource if none provided
  const defaultRenderResource = useCallback((resource, isLoading = false) => (
    <div key={resource.id} className="default-resource">
      {isLoading ? (
        <div>Loading resource...</div>
      ) : (
        <div>{resource.title}</div>
      )}
    </div>
  ), []);

  // Use provided renderResource or fall back to default
  const actualRenderResource = renderResource || defaultRenderResource;

  useEffect(() => {
    const processArtifacts = (text) => {
      // Check for interruption markers
      const isInterrupted = text.includes('[Generation stopped]') || 
                           text.includes('[Generation Stopped]') ||
                           text.includes('<|endoftext|>');
      setWasInterrupted(isInterrupted);

      const parts = [];
      let currentIndex = 0;
      let resourceCount = 0;
      let hasOpenArtifact = false;

      // Look for opening artifact tag
      while (true) {
        const artifactStart = text.indexOf('<artifacts>', currentIndex);
        if (artifactStart === -1) break;

        // Add text before the artifact
        if (artifactStart > currentIndex && !hasOpenArtifact) {
          let beforeArtifact = text.slice(currentIndex, artifactStart);
          beforeArtifact = beforeArtifact.replace(/<\|endofinput\|>.*$/s, '').trim();
          if (beforeArtifact) {
            parts.push(beforeArtifact);
          }
        }

        // Find closing tag
        const artifactEnd = text.indexOf('</artifacts>', artifactStart);
        
        // Handle unclosed artifact tag
        if (artifactEnd === -1) {
          hasOpenArtifact = true;
          const partialContent = text.slice(artifactStart + 10);
          const resourceId = `loading-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
          
          // Create a loading resource
          const loadingResource = parseArtifact(partialContent, resourceId, resourceCount, true, messageId);
          
          parts.push(actualRenderResource(loadingResource, true));
          break;
        }

        resourceCount++;
        const artifactContent = text.slice(artifactStart + 10, artifactEnd);
        const resourceId = `resource-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        const resource = parseArtifact(artifactContent, resourceId, resourceCount, false, messageId);
        parts.push(actualRenderResource(resource));

        currentIndex = artifactEnd + 11; // 11 is length of '</artifacts>'
        hasOpenArtifact = false;
      }

      // Only add remaining text if there's no open artifact
      if (currentIndex < text.length && !hasOpenArtifact) {
        let remainingText = text.slice(currentIndex);
        remainingText = remainingText.replace(/<\|endofinput\|>.*$/s, '').trim();
        if (remainingText) {
          parts.push(remainingText);
        }
      }

      return parts;
    };

    let sanitizedContent = processArtifacts(content);

    // Further sanitize the content by removing any PDF title markers
    sanitizedContent = sanitizedContent.map(part => {
      if (typeof part === 'string') {
        return part.replace(/\[PDF TITLE: .*?\]/g, '').replace(/(?<!\S)>(?!\S)/g, '').trim();
      }
      return part;
    });

    setProcessedContent(sanitizedContent);
  }, [content, renderResource, messageId, actualRenderResource]);

  return (
    <div className="ai-response-content">
      {processedContent.map((part, index) => 
        typeof part === 'string' ? (
          <React.Fragment key={index}>
            <AIDisplayRenderer content={part} />
            {wasInterrupted && (
              <div className="interrupted-message">
                <span>This message was interrupted due to connection issues.</span>
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            {part}
          </React.Fragment>
        )
      )}
    </div>
  );
};

// Update parseArtifact to handle loading state
const parseArtifact = (content, id, count, isLoading = false, messageId) => {
  try {
    // Handle null or undefined content
    if (!content || typeof content !== 'string') {
      return {
        id,
        type: 'generic',
        title: `Invalid Resource ${count}`,
        content: 'Invalid content received',
        isError: true
      };
    }

    // Handle loading state
    if (isLoading) {
      // Try to parse what we can from the partial content
      let parsedTitle = 'Generating Resource...';
      let parsedType = 'loading';
      
      // Check for title tags
      const titleMatch = content.match(/<title>(.*?)<\/title>/);
      if (titleMatch) {
        parsedTitle = `${titleMatch[1]}`;
      }

      // Check for content type indicators
      if (content.includes('<essay>')) {
        parsedType = 'essay';
      } else if (content.includes('<code')) {
        parsedType = 'code';
      } else if (content.includes('<visualization')) {
        parsedType = 'visualization';
      }

      return {
        id,
        type: parsedType,
        title: parsedTitle,
        content: content,
        isLoading: true
      };
    }

    // Add input sanitization for content
    const sanitizedContent = content.replace(/[^\x20-\x7E\s]/g, ''); // Remove non-printable characters

    // Rest of parsing logic with sanitized content
    if (sanitizedContent.includes('<essay>')) {
      const titleMatch = sanitizedContent.match(/<title>(.*?)<\/title>/);
      const contentMatch = sanitizedContent.match(/<content>([\s\S]*?)<\/content>/);
      
      if (!contentMatch) {
        throw new Error('Invalid essay format');
      }

      // Process the content to handle nested code blocks
      let processedContent = contentMatch[1].trim();
      const codeBlocks = [];
      
      // Extract code blocks and replace with placeholders
      processedContent = processedContent.replace(/<code.*?>([\s\S]*?)<\/code>/g, (match, codeContent, offset) => {
        const languageMatch = match.match(/language="(.*?)"/);
        const language = languageMatch ? languageMatch[1].trim() : 'plaintext';
        
        codeBlocks.push({
          type: 'code-block',
          language,
          content: codeContent.trim()
        });
        
        return `__CODE_BLOCK_${codeBlocks.length - 1}__`;
      });

      return {
        id,
        type: 'essay',
        title: titleMatch ? titleMatch[1].trim() : `Essay ${count}`,
        content: processedContent,
        codeBlocks, // Add the code blocks array to the essay object
        messageId: messageId ? messageId : null
      };
    } else if (sanitizedContent.includes('<code')) {
      const languageMatch = sanitizedContent.match(/language="(.*?)"/);
      const titleMatch = sanitizedContent.match(/title="(.*?)"/);
      return {
        id,
        type: 'code',
        title: titleMatch ? titleMatch[1].trim() : `Code Snippet ${count}`,
        language: languageMatch ? languageMatch[1].trim() : 'plaintext',
        content: sanitizedContent.replace(/<code.*?>([\s\S]*?)<\/code>/, '$1'),
        messageId: messageId ? messageId : null
      };
    } else if (sanitizedContent.includes('<visualization')) {
      const vizMatch = sanitizedContent.match(/<visualization.*?>([\s\S]*?)<\/visualization>/);
      const titleMatch = sanitizedContent.match(/title="(.*?)"/);
      
      if (vizMatch && titleMatch) {
        const vizContent = vizMatch[1];
        try {
          // Validate that the content is valid JSON
          JSON.parse(vizContent);
          return {
            id,
            type: 'visualization',
            title: titleMatch[1].trim(),
            content: vizContent,
            messageId: messageId ? messageId : null
          };
        } catch (error) {
          // If JSON parsing fails, return as generic content
          return {
            id,
            type: 'generic',
            title: `Resource ${count}`,
            content: sanitizedContent,
          };
        }
      }
    }
    
    // Default case for unrecognized content
    return {
      id,
      type: 'generic',
      title: `Resource ${count}`,
      content: sanitizedContent,
    };
  } catch (error) {
    return {
      id,
      type: 'error',
      title: `Error in Resource ${count}`,
      content: error.message,
      isError: true
    };
  }
};
