import React, { useState } from 'react';
import { changePassword } from '../../services/api';
import '../../Styles/AuthForms.css';
import '../../Styles/Modal.css';
import { FiCheck, FiAlertCircle } from 'react-icons/fi';

const ChangePassword = ({ onClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    if (newPassword === currentPassword) {
      setError('New password must be different from the current password');
      return;
    }

    try {
      await changePassword(currentPassword, newPassword);
      setSuccess('Password changed successfully');
      setTimeout(onClose, 2000);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to change password');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>&times;</button>
        <div className="auth-form">
          <h2>Change Password</h2>
          {error && (
            <p className="error-message">
              <FiAlertCircle /> {error}
            </p>
          )}
          {success && (
            <p className="success-message">
              <FiCheck /> {success}
            </p>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button">Change Password</button>
          </form>

          <footer className="auth-footer">
            <a href="//cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
            <a href="//cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;