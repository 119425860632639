export const downloadMarkdownAsPDF = async (markdownText, filename = 'document.pdf') => {
    const marked = await import('marked');
    const html2pdf = (await import('html2pdf.js')).default;
    
    // Configure marked options for better code handling
    marked.marked.setOptions({
        highlight: function(code, lang) {
            return code;  // You could integrate Prism.js or highlight.js here
        },
        breaks: true,
        gfm: true
    });
    
    // Convert markdown to HTML
    const htmlContent = marked.marked(markdownText);
    
    // Create container with styling
    const element = document.createElement('div');
    element.innerHTML = htmlContent;
    
    // Update CSS styles for better PDF rendering
    const style = document.createElement('style');
    style.textContent = `
        body {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
            line-height: 1.8;
            color: #2c3e50;
            max-width: 800px;
        }

        pre {
            background-color: #f8f9fa;
            border: 1px solid #eaecef;
            border-radius: 6px;
            padding: 16px;
            margin: 1em 0;
        }

        code {
            font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
            font-size: 14px;
        }
        
        blockquote {
            border-left: 4px solid #42b983;
            padding: 1em 1.2em;
            color: #4a5568;
            background-color: #f8f9fa;
            margin: 1.5em 0;
        }
        
        table {
            border-collapse: collapse;
            width: 100%;
            margin: 1em 0;
            page-break-inside: avoid;
        }
        
        th, td {
            border: 1px solid #e1e4e8;
            padding: 8px 12px;
            text-align: left;
        }

        th {
            background-color: #f6f8fa;
        }
        
        img {
            max-width: 100%;
            height: auto;
            margin: 1em 0;
            page-break-inside: avoid;
        }

        h1, h2, h3, h4, h5, h6 {
            font-weight: 600;
            line-height: 1.25;
            margin-top: 2em;
            margin-bottom: 1em;
            color: #1a202c;
        }

        h1 { font-size: 2em; }
        h2 { font-size: 1.5em; }
        h3 { font-size: 1.25em; }
        
        p {
            margin: 1em 0;
        }

        /* Remove list styling */
        ul, ol {
            list-style: none;
            padding-left: 0;
            margin: 1em 0;
        }

        li {
            margin: 0.3em 0;
            line-height: 1.6;
        }

        /* Remove task list styling */
        .task-list-item {
            list-style: none;
        }

        .task-list-item-checkbox {
            margin-right: 0.5em;
        }

        /* Task lists */
        ul.contains-task-list {
            list-style-type: none;
            padding-left: 0;
        }

        .task-list-item {
            display: flex;
            align-items: flex-start;
            gap: 0.5em;
        }

        .task-list-item-checkbox {
            margin-top: 0.3em;
        }
    `;
    element.appendChild(style);
    element.style.padding = '20px';
    element.style.fontFamily = 'Arial, sans-serif';
    
    // Enhanced PDF options
    const opt = {
        margin: [1, 1, 1, 1], // Increased margins for better readability
        filename: filename,
        image: { type: 'jpeg', quality: 1.0 }, // Maximum image quality
        html2canvas: { 
            scale: 4, // Increased scale for better resolution
            logging: false,
            useCORS: true,
            letterRendering: true,
            windowWidth: 1600 // Increased width for better rendering
        },
        jsPDF: { 
            unit: 'in', 
            format: 'letter', 
            orientation: 'portrait',
            compress: true,
            hotfixes: ['px_scaling'],
            putTotalPages: true
        }
    };
    
    try {
        await html2pdf().set(opt).from(element).save();
        return true;
    } catch (error) {
        console.error('Error generating PDF:', error);
        return false;
    }
};

