import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../Styles/SalesModal.css';
import { X, Check } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { createCheckoutSession, handleStripePortalRedirect } from '../../services/api';

const SalesModal = ({ feature, onClose }) => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const subscription = user?.subscription;
  const [currency, setCurrency] = useState('usd');
  const [billingCycle, setBillingCycle] = useState('monthly');

  const pricingTiers = [
    { 
      name: 'Premium', 
      price: { 
        usd: { monthly: '$5.59', annual: '$49.99' },
        cad: { monthly: '$6.99', annual: '$59.99' },
        eur: { monthly: '€5.59', annual: '€49.99' }
      },
      features: [
        'Access to core AI capabilities',
        'Unlimited AI interactions',
        'PDF Upload Functionality',
      ]
    },
    { 
      name: 'Pro', 
      tagline: '1 week free trial',
      price: { 
        usd: { monthly: '$12.99', annual: '$119.99' },
        cad: { monthly: '$15.99', annual: '$139.99' },
        eur: { monthly: '€12.99', annual: '€119.99' }
      },
      features: [
        'Unlimited AI interactions',
        'Up to 3 PDF uploads per chat',
        'Access to Study Spaces',
      ]
    },
    { 
      name: 'Ultimate', 
      tagline: '1 week free trial',
      price: { 
        usd: { monthly: '$18.99', annual: '$159.99' },
        cad: { monthly: '$21.99', annual: '$189.99' },
        eur: { monthly: '€18.99', annual: '€159.99' }
      },
      features: [
        'Priority response times',
        'Access to beta features',
        'Up to 5 PDF uploads per chat',
        'Access to Study Spaces',
      ]
    }
  ];

  useEffect(() => {
    const detectUserLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        if (data.country_code === 'CA') {
          setCurrency('cad');
        } else if (data.country_code === 'US') {
          setCurrency('usd');
        } else if (data.continent_code === 'EU') {
          setCurrency('eur');
        } else {
          setCurrency('usd'); // Default to USD for other countries
        }
      } catch (error) {
        console.error('Error detecting user location:', error);
        setCurrency('usd'); // Default to USD if there's an error
      }
    };

    detectUserLocation();
  }, []);

  const handleSubscription = async (tier, interval = billingCycle, currencyCode = currency) => {
    if (!isAuthenticated) {
      navigate('/signup', { 
        state: { selectedTier: tier, interval, currency: currencyCode } 
      });
      return;
    }

    try {
      if(subscription && subscription.tier !== "free"){
        await handleStripePortalRedirect();
        return;
      }
      const response = await createCheckoutSession(tier, interval, currencyCode);
      if (response.url) {
        window.location.href = response.url;
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const renderButton = (tier) => {
    if (!user) {
      return (
        <button 
          onClick={() => handleSubscription(tier.name.toLowerCase(), billingCycle, currency)} 
          className="upgrade-button"
        >
          {`Choose ${tier.name}`}
        </button>
      );
    }

    if (subscription && subscription.tier !== 'free') {
      if (subscription.tier.toLowerCase() === tier.name.toLowerCase()) {
        return (
          <button onClick={handleStripePortalRedirect} className="upgrade-button">
            Manage Subscription
          </button>
        );
      } else {
        return null;
      }
    }

    return (
      <button 
        onClick={() => handleSubscription(tier.name.toLowerCase(), billingCycle, currency)} 
        className="upgrade-button"
      >
        {`Choose ${tier.name}`}
      </button>
    );
  };

  return (
    <div className="sales-modal-overlay" onClick={onClose}>
      <div className="sales-modal-content" onClick={e => e.stopPropagation()}>
        <button className="sales-modal-close" onClick={onClose}>
          <X size={24} />
        </button>
        <h2>Unlock {feature}</h2>
        <p className="modal-description">Choose the plan that fits your learning needs and take your studies to the next level!</p>
        
        {window.innerWidth > 538 && (
          <div className="pricing-toggle">
            <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="modal-billing-toggle"
                checked={billingCycle === 'annual'}
                onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
              />
              <label htmlFor="modal-billing-toggle"></label>
            </div>
            <span className={billingCycle === 'annual' ? 'active' : ''}>
              Annual
              <span className="save-badge">Save 30%</span>
            </span>
          </div>
        )}

        {window.innerWidth > 800 && (
          <div className="plans-grid">
            {pricingTiers.map((tier) => (
              <div key={tier.name} className={`plan-card ${tier.name.toLowerCase()}`}>
                <div className="card-header">
                  <h3>{tier.name}</h3>
                  <p className="tagline">{tier.tagline}</p>
                </div>
                <p className="plan-price">
                  {tier.price[currency][billingCycle]}
                  <span className="period">/{billingCycle === 'monthly' ? 'mo' : 'yr'}</span>
                </p>
                <ul className="feature-list">
                  {tier.features.map((item, index) => (
                    <li key={index}><Check size={18} /> {item}</li>
                  ))}
                </ul>
                {renderButton(tier)}
              </div>
            ))}
          </div>
        )}
        
        {window.innerWidth <= 558 && (
          <div className="modal-footer">
            <p>Not sure which plan is right for you?</p>
            <Link to="/pricing" className="view-all-plans">View All Plans</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesModal;
